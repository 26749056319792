import { useTable, useBlockLayout, useResizeColumns } from "react-table";

interface TableProps {
  data: any;
  columns: any;
  modalClass?: any;
  isLoading?: Boolean;
}

const Table: React.FC<TableProps> = ({
  data,
  columns,
  modalClass,
  isLoading,
}) => {
  const { rows, headerGroups, getTableProps, getTableBodyProps, prepareRow } =
    useTable({ columns, data }, useBlockLayout, useResizeColumns);

  return (
    <table {...getTableProps()} className={`edu-table  ${modalClass}`}>
      <thead className="table-header">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{ width: "100%" }}>
            {headerGroup.headers.map((col) => (
              <th {...col.getHeaderProps()}>{col.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>

      {rows.length > 0 ? (
        <tbody {...getTableBodyProps()} className="table-body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  width: "100%",
                }}
              >
                {row.cells.map((cell: any) => {
                  const customStyles = cell.column?.customStyles || {};

                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ ...customStyles }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tfoot className="no-table-data-message">
          <tr>
            <td colSpan={10}>
              {isLoading ? "Loading data..." : "No content available"}
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default Table;
