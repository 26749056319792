import { getSchoolSubscriptions } from "@api/rifService";
import { useCleverContext } from "@context/clever";
import DashboardContext from "@context/dashboardInfo";
import { useUserContext } from "@context/user";
import { Field, Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Links(props: { userType: string }) {
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const isClever = cleverContext.isCleverUser();
  const dashboard: any = useContext(DashboardContext);
  const [info, setInfo] = useState();
  let body;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const schoolId = userContext.userData.edu.user.rrkSchoolId;
  let teacherId: any;

  if (userContext.userData.edu.user.type === "admin") {
    teacherId = Number(userContext.userData.edu.user.relatedAccountId);
  } else {
    teacherId = Number(userContext.userData.edu.user.rrkEduUserId);
  }

  const createMarkup = () => {
    if (props.userType !== "multiple") {
      return {
        __html: dashboard.information.teachers.links_normal,
      };
    }

    return;
  };

  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (!isClever) {
      getSchoolSubscriptions(requestConfig, schoolId, teacherId).then(
        (subscriptionsResponse) => {
          setSubscriptions(subscriptionsResponse);
        },
      );
    }
  }, [userContext.subscriptions]);

  const renderClassrooms = () => {
    return subscriptions.map((sub: any, index: number) => {
      return (
        <option value={sub.hash} key={index}>
          {sub.className}
        </option>
      );
    });
  };

  const submitHandler = (values: FormikValues) => {
    if (values.classroom !== "") {
      setButtonDisabled(true);
      userContext.setFormProgress(50);
      navigate(`/sigin/classroom/${values.classroom}`, {
        state: { teacher: true },
      });
    }
  };

  useEffect(() => {
    setInfo(<div dangerouslySetInnerHTML={createMarkup()} />);
  }, []);

  if (!isClever) {
    if (userContext.hasMultipleStudents) {
      body = (
        <div className="active-subscription">
          <h3>Bookmark Skybrary for your classroom</h3>
          {info}
          <Formik initialValues={{ classroom: "" }} onSubmit={submitHandler}>
            <Form>
              <Field as="select" name="classroom">
                <option value="default">- Select -</option>
                {renderClassrooms()}
              </Field>
              <button
                type="submit"
                className="btn-red-damask btn-spinner-indicator"
                disabled={buttonDisabled}
              >
                Go to student sign in
              </button>
            </Form>
          </Formik>
        </div>
      );
    } else {
      body = (
        <div className="trial-user">
          <div className="trial title">
            <h3>New here?</h3>
          </div>
          <div className="trial get-started">
            <Link to="/teachers/manage/rosters" className="btn btn-red-damask">
              Get Started
            </Link>
          </div>
        </div>
      );
    }
    return <div className="links">{body}</div>;
  }
  return <></>;
}

export default Links;
