import { useContext, useState } from "react";
import {
  uploadStudentToRoster,
  uploadTeachersCsv,
  getTeacherUploadStatus,
} from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";
import errorImage from "../../../../images/icons/text-x-generic.png";

const UploadCsvModal = (props: any) => {
  const userContext = useContext(UserContext);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  let csvName = "skybrary-school-students-template.csv";

  if (props.csv === "teachers") {
    csvName = "skybrary-school-teachers-template.csv";
  }

  const [submittingStudents, setSubmittingStudents] = useState(false);
  const [submittingTeachers, setSubmittingTeachers] = useState(false);
  const [emptyFile, setEmptyFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errorFile, setErrorFile] = useState(false);
  const [errorFileText, setErrorFileText] = useState("");

  const handleSubmit = async () => {
    setEmptyFile(false);
    userContext.setFormProgress(50);

    if (fileUploaded) {
      if (props.csv === "students") {
        setSubmittingStudents(true);
        uploadStudentToRoster(
          requestConfig,
          formData,
          props.schoolId,
          props.subscriptionId
        ).then((response) => {
          if (response.data.response === undefined) {
            props.onSubmit(response, formData, props.subscriptionId);
          } else {
            setSubmittingStudents(false);
            userContext.setFormProgress(0);
            setErrorFile(true);
            setErrorFileText(response.data.response.data.errorMessage);
          }
        });
      } else {
        setSubmittingTeachers(true);
        await uploadTeachersCsv(
          requestConfig,
          formData,
          props.schoolId
        ).then((response) => {
          if (response.status) {
            props.onSubmit(
              {
                from: "upload-teacher",
                batchId: response.data.data.data.batchId,
              }
            );
          } else {
            setSubmittingTeachers(false);
            userContext.setFormProgress(0);
            setErrorFile(true);
            setErrorFileText(response.data.response.data.errorMessage);
          }
        })
        userContext.setFormProgress(100);
      }
    } else {
      userContext.setFormProgress(100);
      setEmptyFile(true);
    }
  };

  let file: any;
  const [formData, setFormData] = useState(new FormData());
  let [fileUploaded, setFileUploaded] = useState(false);
  const [errorCsv, setErrorCsv] = useState({
    status: false,
    name: "",
  });

  const onUploadFile = async (e: any) => {
    setErrorCsv({ status: false, name: "" });
    if (e.target.files[0].type === "text/csv") {
      setFileName(e.target.files[0].name);
      setFileUploaded(true);
      fileUploaded = true;
      file = e.target.files[0];
      formData.append("file", file);
    } else {
      setErrorCsv({ status: true, name: e.target.files[0].name });
      e.target.value = null;
    }
  };

  const removeFile = () => {
    setErrorFile(false);
    setFileUploaded(false);
    formData.delete("file");
  };

  return (
    <div className="modal-body-wrapper">
      <>
        <h1 className="title mb-5">How to Format Your .CSV File</h1>
        {emptyFile && <TextError wrapper="div" text="Select a file" />}
        {errorFile && <TextError wrapper="div" text={errorFileText} />}
        <ol className="csv-steps">
          <li>
            <a
              href="../../../../../resources/skybrary-school-teachers-template.csv"
              className="link"
              download={csvName}
            >
              Download our Sample Template
            </a>
          </li>
          <li>
            {props.csv === "teachers" ? (
              <span>
                Fill in your teachers' information. "First Name", "Last Name".
                and "Email" are all required fields.
              </span>
            ) : (
              <span>
                Fill in your students' information. "Last Name" and "Parent
                Email" are both optional - only "First Name" is required.
              </span>
            )}
          </li>
          <li>
            Selet "Choose File" below and navigate to your populated CSV
            template.
          </li>
          <li className="csv-step">Select "Upload" to add your teachers.</li>
        </ol>
        <p className="excel-csv">
          Trying to use Microsoft Excel® to generate your CSV file?
        </p>
        <p className="office-support">
          <a
            href="https://support.office.com/en-us/article/Import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#bmexport"
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            Please see Microsoft Office support here for details.
          </a>
        </p>
        {props.csv === "teachers" ? (
          <>
            <p className="description first">
              Please note our limit of 100 teachers per file.
            </p>
            <p className="description">
              If a file with more than 100 teachers is uploaded we will load
              only the first 100 teachers.
            </p>
          </>
        ) : (
          <>
            <p className="description first">
              Please note our limit of 35 students per file.
            </p>
            <p className="description">
              If a file with more than 35 students is uploaded we will load only
              the first 35 students.
            </p>
          </>
        )}

        <form>
          {errorCsv.status && (
            <div className="my-2 mx-5">
              <TextError
                text={
                  <>
                    <span>The selected file Screenshot from </span>
                    <span className="csv-file-name">${errorCsv.name}</span>
                    <span>
                      cannot be uploaded. Only files with the following
                      extensions are allowed: csv
                    </span>
                    .
                  </>
                }
                wrapper={"div"}
              />
            </div>
          )}
          <div className="input-container">
            {errorFile && (
              <div className="input-error">
                <span>
                  <img src={errorImage} alt="" />
                  <p>{fileName}</p>
                </span>
                <button type="button" onClick={removeFile} className="btn-mojo-modal">
                  Remove
                </button>
              </div>
            )}
            {!errorFile && (
              <input
                className="form-input"
                type="file"
                accept=".csv"
                id="csv-input"
                onChange={onUploadFile}
              ></input>
            )}
          </div>
          <div className="buttons-wrapper">
            <button
              type="button"
              className="btn-modal btn-mojo-modal"
              onClick={() => props.onClick()}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn-modal btn-brandy-punch btn-spinner-indicator"
              disabled={submittingStudents}
            >
              Upload
            </button>
          </div>
        </form>
      </>
    </div>
  );
};

export default UploadCsvModal;
