import React from "react";
import { useContext, useEffect, useState } from "react";
import Collapse from "../../../Common/Collapse";
import Book from "./Book";
import Video from "./Video";
import PaginationTable from "./PaginationTable";
import Filter from "./Filter";
import { getCatalogs } from "../../../../../api/rifService";
import UserProvider from "../../../../../context/user";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";
import PopUpModal from "../../Modals/PopUpModal";
import { DEFAULT_TEACHER_SKY_THEME } from "../../../../../utils/constants";

let controller = new AbortController();

function Library() {
  const collapseTextField = ["teachers", "library"];
  const tokenContext = useContext(UserProvider);
  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${tokenContext.token}`,
    },
  };

  let books: any;

  const [arrayBooks, setArrayBooks] = useState([]);
  const [foundResults, setFoundResults] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [clearLoading, setClearLoading] = useState(false);

  const getFullCatalog = async (
    page: number,
    title: string,
    listeningLevel: string,
    readingLevel: string,
    genreId: number,
    type: number,
    unitLevel: number,
    islandId: number,
    sort: string,
    items?: number,
  ) => {
    setDisabledButton(true);
    const parameter: any = await getCatalogs(
      requestConfig,
      page,
      title,
      listeningLevel,
      readingLevel,
      genreId,
      type,
      unitLevel,
      islandId,
      sort,
      (items = isCleverTheme(userContext, cleverContext) ? 4 : 20),
    );
    const { data } = parameter;
    setFoundResults(data?.total);
    setLastPage(data?.last_page);
    renderBooks(data ? data?.data : []);
    tokenContext.setFormProgress(100);
    setDisabledButton(false);
    setClearLoading(false);
  };

  useEffect(() => {
    document.title = "Library | Reading Is Fundamental";
    tokenContext.setFormProgress(50);
    getFullCatalog(
      actualPage,
      "",
      "0",
      "0",
      0,
      0,
      0,
      -1,
      sortFilter,
      isCleverTheme(userContext, cleverContext) ? 4 : 20,
    );
    return () => {
      handleCancel();
    };
  }, []);

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    tokenContext.setFormProgress(0);
  };

  const [disabledButton, setDisabledButton] = useState(false);

  const renderBooks = (array: any) => {
    books = [];
    array.forEach((book: any) => {
      books.push({
        ...book,
        itemElem: "",
      });
    });
    setArrayBooks(books);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "itemElem",
        width: isCleverTheme(userContext, cleverContext) ? 680 : 825,
        Cell: ({ row }: any) => {
          const item = row.original;
          if (item.hasOwnProperty("bookId")) {
            return (
              <Book
                key={row.index}
                title={item.title ? item.title : "-"}
                thumb={item.thumb ? item.thumb : undefined}
                author={item.author ? item.author : "-"}
                publisher={item.publisher ? item.publisher?.name : "-"}
                genre={item?.genre ? item.genre?.name : "-"}
                readingLevel={item.readingLevel ? item.readingLevel : "-"}
                island={item.islands?.length > 0 ? item.islands[0].name : "-"}
                desc={item.desc ? item.desc : "-"}
                listeningLevel={item.listeningLevel ? item.listeningLevel : "-"}
              />
            );
          } else {
            return (
              <Video
                key={row.index}
                island={item.islands ? item.islands?.name : "-"}
                listeningLevel={item.gradeBand ? item.gradeBand : "-"}
                title={item.title ? item.title : "-"}
                keyframe={item.keyframe ? item.keyframe : undefined}
                desc={item.description ? item.description : "-"}
                vimeoId={item.vimeoId ? item.vimeoId : null}
              />
            );
          }
        },
      },
    ],
    [],
  );

  const data = React.useMemo(() => arrayBooks, [arrayBooks]);

  const handlePage = (newPage: number, type: number) => {
    setActualPage(newPage);
    tokenContext.setFormProgress(50);
    getFullCatalog(
      newPage,
      filters.title,
      filters.listeningLevel,
      filters.readingLevel,
      filters.genre,
      filters.type,
      filters.unitLevel,
      filters.islandId,
      sortFilter,
    ).then(() => {
      setIsTableLoading(false);
    });
  };

  const [sortFilter, setSortFilter] = useState("alpha");

  const handleSort = (sort: string) => {
    setSortFilter(sort);
    tokenContext.setFormProgress(50);
    getFullCatalog(
      1,
      filters.title,
      filters.listeningLevel,
      filters.readingLevel,
      filters.genre,
      filters.type,
      filters.unitLevel,
      filters.islandId,
      sort,
    );
  };

  const [filters, setFilters] = useState({
    title: "",
    listeningLevel: "0",
    readingLevel: "0",
    genre: 0,
    type: 0,
    unitLevel: 0,
    islandId: -1,
  });

  const handleFilterInformation = (values: any, clear: boolean) => {
    if (clear) {
      setClearLoading(true);
    }
    setFilters({
      title: values.title,
      listeningLevel: values.listeningLevel,
      readingLevel: values.readingLevel,
      genre: values.genre,
      type: values.type,
      unitLevel: values.unitLevel,
      islandId: values.islandId,
    });
    setActualPage(1);
    tokenContext.setFormProgress(50);
    getFullCatalog(
      1,
      values.title,
      values.listeningLevel,
      values.readingLevel,
      values.genre,
      values.type,
      values.unitLevel,
      values.islandId,
      clear ? "alpha" : sortFilter,
    );
  };

  return (
    <Dashboard rol="teacher">
      <div className="library-container">
        {isCleverTheme(userContext, cleverContext) ? (
          <div className="row home-dashboard">
            <div className="left col-4 p-0">
              <h1>Library</h1>
              <div className="info">
                <p>
                  Search our award-winning library and access any of the
                  hundreds of books and educational videos.
                </p>
              </div>
              <Filter
                onSubmit={handleFilterInformation}
                disabledButton={disabledButton}
                page={actualPage}
                handleSortChange={handleSort}
                clearLoading={clearLoading}
              />
              <PopUpModal
                title={DEFAULT_TEACHER_SKY_THEME.library.title}
                body={DEFAULT_TEACHER_SKY_THEME.library.body}
                modalClass="library-help-modal"
              />
            </div>
            <div className="right col-8 m-0 p-0 edu-large-widget white-container">
              <div className="library-wrapper">
                <div className="counter">{`${foundResults} results found.`}</div>
                <PaginationTable
                  data={data}
                  columns={columns}
                  page={actualPage}
                  handleChangePage={handlePage}
                  lastPage={lastPage}
                  isLoading={isTableLoading}
                  setIsLoading={setIsTableLoading}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <Collapse
              title="Library"
              dashboardField={collapseTextField}
              bodyImg=""
            />
            <div className="library-wrapper white-container">
              <Filter
                onSubmit={handleFilterInformation}
                disabledButton={disabledButton}
                page={actualPage}
                handleSortChange={handleSort}
                clearLoading={clearLoading}
              />
              <div className="counter">{`${foundResults} results found.`}</div>
              <PaginationTable
                data={data}
                columns={columns}
                page={actualPage}
                handleChangePage={handlePage}
                lastPage={lastPage}
              />
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
}

export default Library;
