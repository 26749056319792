import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import DashboardContext from "../../../context/dashboardInfo";

function CollapseComponent (props: {title: String; dashboardField: any; bodyImg: any;}) {
  const [open, setOpen] = useState(true);
  let buttonClasses = "";
  let labelText = "";

  const dashboard = useContext(DashboardContext);
  const [collapseText, setCollapseText] = useState(<div />);

  const createMarkup = () => {
    if (props.dashboardField.length > 0) {
      const userType = props.dashboardField[0];
      const section = props.dashboardField[1];
      const dashboardInfo = dashboard.information?.[userType]?.[section]?.value;
      if (dashboardInfo) {
        return { __html: dashboardInfo };
      }
    }
    return { __html: '' };
  };


  useEffect(() => {
    if (props.dashboardField[0] !== "") {
      setCollapseText(<div dangerouslySetInnerHTML={createMarkup()} />);
    }
  }, []);

  if (open) {
    buttonClasses = " open";
    labelText = "Hide";
  }
  else {
    labelText = "Show";
  }

  return (
    <div className="collapse-page-title">
      <h1 className={buttonClasses ? buttonClasses : "hide"}>
        <span className="title">{props.title}</span>
        <div className="bar"></div>
      {(props.dashboardField !== "" || props.title === "Teacher's Guides") && <> <label>{labelText}</label>
       <Button
        variant=""
        className={`collapse-button border-0 ${buttonClasses}`}
        onClick={() => setOpen(!open)}
        aria-controls="collapse-text"
        aria-expanded={open}
      /></>}
      </h1>
      <Collapse in={open}>
        <div className="collapse-body">
          {props.bodyImg === "" ? collapseText : props.bodyImg}
        </div>
      </Collapse>
    </div>
  );
}

export default CollapseComponent;
