import { Link } from "react-router-dom";
import { useContext } from "react";
import CleverContext from "../../../../context/clever";
import { isCleverTheme } from "../../../../utils/helpers";
import UserContext from "../../../../context/user";

function CurriculumResources(props: {
  imageUrl: string;
  title: any;
  description: string;
  file: string;
  fileDownload: string;
  isTrial: boolean;
}) {
  let description = (
    <div dangerouslySetInnerHTML={{ __html: props.description }} />
  );

  let fileUrl = '';
  let fileDownloadUrl = '';
  let relativeFilePath = '';
  if (props.hasOwnProperty('file') && props.file !== '' && props.file !== null) {
    fileUrl = props.file;
    const splitString = '/storage/curriculums/';
    const fileParts = fileUrl.split(splitString);
    if (fileParts.length > 1) {
      relativeFilePath = fileParts[1];
    }
    fileDownloadUrl = splitString + relativeFilePath;
  }

  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  return (
    <div className="modal-body-wrapper">
      <img className="curriculum-image" src={props.imageUrl} alt="" />
      <div className="description-wrapper">
        <div className="title">{props.title}</div>
        <div className="description">{description}</div>
        <div className="actions">
          {!props.isTrial ? (
            <>
              {props.file !== null && (
                <>
                  <a
                    href={fileUrl}
                    type="button"
                    className={isCleverTheme(userContext, cleverContext) ? ("button btn btn-moderate-green view-button") : ("btn btn-table-di-serria download-button")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                  <a
                    href={fileDownloadUrl}
                    type="button"
                    className={isCleverTheme(userContext, cleverContext) ? ("button btn btn-moderate-green download-button") : ("btn btn-table-brandy-punch download-button")}
                    download={relativeFilePath}
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </>
              )}
            </>
          ) : (
            <>
              <p className="free-trial-mode-text">
                Unit unavailable in Free Trial mode. Please purchase a
                subscription to access all units.
              </p>
              <Link
                to="/purchase/from-trial/how"
                className="btn btn-table-brandy-punch"
              >
                {" "}
                PURCHASE{" "}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CurriculumResources;
