import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function PopUpModal(props: { title: any, body: any, modalClass: string }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="help-button">
        <button className="icon" onClick={handleShow}>
        </button>
      </div>
      <Modal
        className={`help-modal ${props.modalClass}`}
        show={show}
        onHide={handleClose}
        centered
        backdropClassName={'sky-modal-theme'}
      >
        <Modal.Header>
          <Modal.Title>{ props.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{ props.body }</Modal.Body>
      </Modal>
    </>
  );
}

export default PopUpModal;
