import { useState, useContext } from "react";
import ModalWindow from "../../../Common/ModalWindow";
import SneakPeak from "../../Modals/SneakPeak";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";

interface BookProps {
  title: string;
  thumb: string;
  author: string;
  publisher: string;
  genre: string;
  readingLevel: string;
  island: string;
  desc: string;
  listeningLevel: string | null;
}

function Book(props: BookProps) {
  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = () => {
    setModal({
      show: true,
      class: "sneak-peak-modal",
      onClick: handleClose,
      header: <h1>{props.title}</h1>,
      body: (
        <SneakPeak
          thumb={props.thumb}
          description={props.desc}
          listeningLevel={props.listeningLevel}
          genre={props.genre}
          readingLevel={props.readingLevel}
          type="book"
          vimeoId=""
        />
      ),
    });
  };

  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  return (
    <div className="book-wrapper">
      <div className="thumbnail">
        <img src={props.thumb} alt="" />
      </div>
      <div className="info">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <div className="first-column">
              <div className="title">{props.title}</div>
              <div className="description">{props.desc}</div>
              <div className="extra-description">
                Read this book by launching the Skybrary app.{" "}
              </div>
            </div>
            <div className="second-column">
              <div className="author">{`Author: ${props.author}`}</div>
              <div className="publisher">{`Publisher: ${props.publisher}`}</div>
              <div className="genre">{`Genre: ${props.genre}`}</div>
              <div className="reading">{`Reading Level: ${props.readingLevel}`}</div>
              <div className="island">{`Islands: ${props.island}`}</div>
            </div>
          </>
        ) : (
          <>
            <div className="detail">
              <div className="title">{props.title}</div>
              <div className="author">{`Author: ${props.author}`}</div>
              <div className="publisher">{`Publisher: ${props.publisher}`}</div>
              <div className="genre">{`Genre: ${props.genre}`}</div>
              <div className="reading">{`Reading Level: ${props.readingLevel}`}</div>
              <div className="island">{`Islands: ${props.island}`}</div>
            </div>
            <div className="actions">
              <button
                type="button"
                onClick={handleModal}
                className="btn btn-di-serria"
              >
                Sneak Peak
              </button>
            </div>
          </>
        )}
      </div>
      <ModalWindow
        show={modal.show}
        class={modal.class}
        onClick={modal.onClick}
        header={modal.header}
        body={modal.body}
      />
    </div>
  );
}

export default Book;
