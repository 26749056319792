import { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../../../context/user";
import csv_image from "../../../../images/import-csv.png";
import manual_image from "../../../../images/import-manual.png";

function AddStudents(props: any) {
  const userContext = useContext(UserContext);
  const fullName = `${userContext.userData.edu.user.firstName} ${userContext.userData.edu.user.lastName}`;

  return (
    <div className="modal-body-wrapper">
      <div className="center">
        Hello, {fullName}! It's time to add your students to your classroom.
        <br />
        You can upload your students via a CSV text file or enter them manually.
      </div>
      <div className="center">
        <div className="csv col col-5">
          <h3>Upload File</h3>
          <div className="icon center csv">
            <img src={csv_image} alt="" />
          </div>
        </div>
        <div className="manual col col-5">
          <h3>Enter students manually.</h3>
          <div className="icon center manual">
            <img src={manual_image} alt="" />
          </div>
        </div>
      </div>
      <div className="center upload-buttons">
        <div className="csv col col-5">
          <button
            type="button"
            className="btn btn-brandy-punch"
            onClick={() => props.onUpload(props.subscriptionId)}
          >
            Upload
          </button>
        </div>
        <div className="manual col col-5">
          <Link
            to={`/teachers/manage/rosters/${props.subscriptionId}`}
            className="btn btn-brandy-punch"
          >
            Enter
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddStudents;