import React, { useContext } from "react";
import UserContext from "../../../../../context/user";
import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse"
import { parentText } from "../../../../../utils/constants";
import Dashboard from "../../../../../pages/dashboard/Dashboard";

const ParentTos = () => {
  const items = [
    {
      step: 1,
      text: "Parents Dashboard",
      link: "/parent/dashboard",
    },
    {
      step: 2,
      text: "Terms Of Use",
      link: "/parent/pages/tos",
    },
  ];
  
  return (
    <Dashboard rol="parent">
      <div className="tos-page-container">
        <Steeper steps={items} activeStep={2} steeperType="dashboard" />
        <CollapseText
            title="Terms of Use"
            dashboardField=""
            bodyImg=""
        />
        <div className="page-body">
          {parentText.termsOfService}
        </div>
      </div>
    </Dashboard>
  )
}

export default ParentTos;