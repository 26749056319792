import { useState, useContext } from "react"
import Dashboard from "../../../../../pages/dashboard/Dashboard"
import FormCheckout from "../../../../Forms/purchase/FormCheckout"
import TextError from "../../../../TextError"
import UserContext from "../../../../../context/user"
import CleverContext from "../../../../../context/clever"
import PopUpModal from "../../../Teacher/Modals/PopUpModal"
import { isCleverTheme } from "../../../../../utils/helpers"
import { DEFAULT_DISTRICT_ADMIN_SKY_THEME } from "../../../../../utils/constants"

const TeacherPurchase = () => {
  const cleverContext = useContext(CleverContext)
  const userContext: any = useContext(UserContext)

  const [emptyError, setEmptyError] = useState(false)

  return (
    <Dashboard rol="teacher">
      <div className="purchase-admin purchase-teacher-gift">
        <PopUpModal
          title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.title}
          body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.body}
          modalClass={'district-report-modal'}
        />

        {emptyError && (
          <div className={`${!isCleverTheme(userContext, cleverContext) && 'mx-3'} empty-error`}>
            <TextError
              text="You have to select some items to buy"
              wrapper="div"
            />
          </div>
        )}
        <div className="admin-purchase-container">
          <FormCheckout
            formSteps={["gift"]}
            form="gift"
            renewIds={[]}
            userInfo=""
            onError={setEmptyError}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export default TeacherPurchase
