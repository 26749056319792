import { getSummaryOfCleverDistrict } from "@api/rifService";
import { useCleverContext } from "@context/clever";
import { useUserContext } from "@context/user";
import { format, getMonth, getYear } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";

function ExtraInfo() {
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const isClever = cleverContext.isCleverUser();
  const [formattedDate, setFormattedDate] = useState("");
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalBooksRead, setTotalBooksRead] = useState(0);
  const [totalTimeReading, setTotalTimeReading] = useState(0);
  const [renewalYear, setRenewalYear] = useState(0);
  const [renewalMonth, setRenewalMonth] = useState(0);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  function setSummaryReport(schoolReports: any) {
    let tempBooksRead = 0;
    let temptotalTimeReading = 0;
    let temptotalStudents = 0;
    let date = new Date(userContext.subscriptions[0].expirationDate);
    let subscriptions = userContext.subscriptions;

    if (userContext.userData.edu.user.type === "admin") {
      subscriptions = subscriptions.filter(
        (subscription: any) =>
          subscription.teacherId === userContext.adminTeacher[0].edu.eduUserId,
      );
    } else {
      subscriptions = subscriptions.filter(
        (subscription: any) =>
          subscription.teacherId === userContext.userData.edu.user.rrkEduUserId,
      );
    }

    subscriptions.forEach((sub) => {
      for (let x = 0; x < schoolReports.length; x++) {
        if (schoolReports[x].subId === sub.rrkSubscriptionEduId) {
          tempBooksRead += schoolReports[x].booksRead;
          temptotalTimeReading += schoolReports[x].totalReadingTime;
          temptotalStudents += schoolReports[x].numberOfStudents;
        }

        const expirationDate = new Date(sub.expirationDate);
        if (expirationDate < date) {
          date = expirationDate;
        }
      }
    });

    setTotalBooksRead(tempBooksRead);
    setTotalStudents(temptotalStudents);
    setTotalTimeReading(
      (temptotalTimeReading = Math.ceil(temptotalTimeReading / 3600)),
    );

    if (date !== null) {
      setFormattedDate(format(new Date(date), "MM/dd/yyyy"));
      const renewalDate = utcToZonedTime(date, "UTC");
      const actualDate = new Date();

      setRenewalYear(getYear(renewalDate) - getYear(actualDate));
      setRenewalMonth(getMonth(renewalDate) - getMonth(actualDate));
    }
  }

  function setSummaryReportForCleverDistrict() {
    getSummaryOfCleverDistrict(
      requestConfig,
      userContext.subscriptions[0].teacherId,
    ).then((summaryReport: any) => {
      const { subscriptions } = summaryReport.data;
      const timestamp = subscriptions.earliestExpirationDate;
      // Convert timestamp in miliseconds
      const date = new Date(timestamp * 1000);
      let timeRead = summaryReport.data.booksHistory.totalTimeReading;
      setTotalBooksRead(
        summaryReport.data.booksHistory.amountOfUniqueBooksRead,
      );
      setTotalStudents(summaryReport.data.students.amountOfStudents);
      setTotalTimeReading((timeRead = Math.ceil(timeRead / 3600)));
      setFormattedDate(format(date, "MM/dd/yyyy"));

      const renewalDate = utcToZonedTime(date, "UTC");
      const actualDate = new Date();

      setRenewalYear(getYear(renewalDate) - getYear(actualDate));
      setRenewalMonth(getMonth(renewalDate) - getMonth(actualDate));
    });
  }

  useEffect(() => {
    if (cleverContext.isCleverUser()) {
      setSummaryReportForCleverDistrict();
    } else {
      setSummaryReport(userContext.schoolReports);
    }
  }, [userContext.subscriptions, userContext.schoolReports]);

  useEffect(() => {
    if (totalStudents > 0) {
      userContext.saveStudents(true);
    } else {
      userContext.saveStudents(false);
    }
  }, [totalStudents]);

  return (
    <div className="extra-info">
      <div
        className={`info subscription ${formattedDate && renewalYear < 1 && renewalMonth < 3 ? "close-to" : ""}`}
      >
        <span className="icon"></span>
        <span className="stats-label">Subscription Renewal Date:</span>
        <span className="val">{formattedDate ? formattedDate : "-"}</span>
      </div>
      <div className="info students">
        <span className="icon"></span>
        <span className="stats-label">
          Total {isClever ? "Number of" : ""} Students (all classes):
        </span>
        <span className="val">{totalStudents}</span>
      </div>
      <div className="info books">
        <span className="icon"></span>
        <span className="stats-label">Total Books Read:</span>
        <span className="val">{totalBooksRead}</span>
      </div>
      <div className="info time info-time">
        <span className="icon"></span>
        <span className="stats-label">
          Total Time Reading {isClever ? "" : "(hrs)"}
        </span>
        <span className="val">{totalTimeReading}</span>
      </div>
    </div>
  );
}

export default ExtraInfo;
