import { useEffect, useState } from "react";
import play from "../../../../images/icons/play_icon.png";
interface SneakProps {
  thumb: string;
  description: string;
  listeningLevel: string | null;
  genre: string | null;
  readingLevel: string | null;
  type: string;
  vimeoId: string;
  watch: boolean;
}

const SneakPeakModal = (props: SneakProps) => {
  let listeningLevel = "Unspecified";

  if (props.listeningLevel !== null) {
    listeningLevel = props.listeningLevel;
  }

  const [content, setContent] = useState(<div/>);

  const handleWatch = () => {
    setContent  (
      <>
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="508"
        height="400"
        src={`//player.vimeo.com/video/${props.vimeoId}?api=1&player_id=player`}
        className="sneak-video"
        allowFullScreen
      ></iframe>
      <div className="sneak-description">{props.description}</div>
    </>
    );
  };

  useEffect(() => {
    if (props.watch) {
      handleWatch();
    } else {
      if (props.type === "book") {
        setContent  (
          <div>
            <div className="thumb-container">
              <img className="thumb" src={props.thumb} alt="Book" />
            </div>
            <div className="information">
              <p className="description">{props.description}</p>
              <p className="info">Genre : {props.genre}</p>
              <p className="info">Listening Level : {listeningLevel}</p>
              <p className="info">Reading Level : {props.readingLevel}</p>
            </div>
          </div>
        );
      } else {
        setContent (
          <div>
            <div className="thumb-video-container">
              <img className="thumb" src={props.thumb} alt="Book" />
            </div>
            <div className="information-video mt-4">
              <p className="description">{props.description}</p>
            </div>
            <div className="button-video">
              <button
                className="btn-brandy-punch watch-button"
                type="button"
                onClick={handleWatch}
              >
                WATCH <img className="ms-3" src={play} alt="play" />
              </button>
            </div>
          </div>
        );
      }
    }
  },[])

  return <div className="modal-body-wrapper">{content}</div>;
};

export default SneakPeakModal;
