import Steeper from "../../../steeper/Steeper";
import Table from "../Table";
import MyAccountTabs from "../../Admin/AdminSections/AdminManageAccount/MyAccountTabs";
import React, { useContext, useEffect } from "react";
import CollapseText from "../Collapse";
import { useState } from "react";
import EditMyAccountModal from "../../Common/Modals/EditMyAccount";
import ModalWindow from "../../Common/ModalWindow";
import Dashboard from "../../../../pages/dashboard/Dashboard";
import DesignateAdmin from "../../Admin/AdminModals/DesignateAdmin";
import UserContext from "../../../../context/user";
import TextSuccess from "../../../TextSuccess";
import TextError from "../../../TextError";
import ManageTeacherModal from "../../Admin/AdminModals/Add-EditTeacher";
import { getTeachers } from "../../../../api/rifService";

const MyAccount = (props: { rol: String }) => {
  const userContext: any = useContext(UserContext);

  const [teacherAdded, setTeacherAdded] = useState(false);
  const [errorDesignate, setErrorDesignate] = useState(false);

  let body = <div />;
  let fourthHeader = "";
  let firstColumnWidth = 0;
  let secondColumnWidth = 0;
  let thirdColumnWidth = 0;
  let fourthColumnWidth = 0;
  let dataFourthColumn = (
    <div>
      <button
        type="button"
        className="btn btn-table-brandy-punch edit-button"
        onClick={() => handleModal("manage-account-modal")}
      >
        EDIT
      </button>
      {props.rol === "admin" && (
        <button
          type="button"
          className="btn btn-table-brandy-punch designate-admin-button btn-spinner-indicator"
          onClick={() => handleModal("designate-admin-modal")}
        >
          DESIGNATE ADMIN
        </button>
      )}
    </div>
  );
  const [settedAccountData, setSettedAccountData] = useState([]);
  let accountData: any;

  const collapseTextField = ["teachers", "manage_account"];
  let editAccountTitle = "Manage My Account";

  if (props.rol === "admin") {
    const items = [
      {
        step: 1,
        text: "Admin Dashboard",
        link: "/adm/dashboard",
      },
      {
        step: 2,
        text: "Account Management",
        link: "/adm/manage",
      },
    ];

    firstColumnWidth = 105.24;
    secondColumnWidth = 105.45;
    thirdColumnWidth = 400.31;
    fourthColumnWidth = 251;

    body = (
      <div className="manage-account">
        <Steeper steps={items} activeStep={2} steeperType="dashboard" />
        <div className="row page-title">
          <h1>
            <span className="title">My Account</span>
            <div className="bar my-account-bar"></div>
          </h1>
        </div>
        {teacherAdded && (
          <div className="text-success-dashboards">
            <TextSuccess text="Teacher Added" />
          </div>
        )}
        {errorDesignate && (
          <div className="my-2">
            <TextError
              wrapper="div"
              text="Error, Cannot designate this teacher as admin."
            />
          </div>
        )}
        <div className="account-tabs">
          <MyAccountTabs />
        </div>
      </div>
    );

    accountData = [
      {
        col1: userContext.userData.edu.user.firstName,
        col2: userContext.userData.edu.user.lastName,
        col3: userContext.userData.emailAddress,
        col4: dataFourthColumn,
      },
    ];
  } else if (props.rol === "teacher") {
    editAccountTitle = "My Account";
    firstColumnWidth = 144.61;
    secondColumnWidth = 143.77;
    thirdColumnWidth = 494;
    fourthColumnWidth = 79.95;
    fourthHeader = "Edit";

    body = (
      <CollapseText
        title={"My Account"}
        dashboardField={collapseTextField}
        bodyImg=""
      />
    );

    accountData = [
      {
        col1: userContext.userData.edu.user.firstName,
        col2: userContext.userData.edu.user.lastName,
        col3: userContext.userData.emailAddress,
        col4: dataFourthColumn,
      },
    ];

    if (userContext.userData.type === "edu-admin") {
      accountData.col1 = userContext.adminTeacher[0].edu.firstName
      accountData.col2 = userContext.adminTeacher[0].edu.lastName
    }
  } else {
    editAccountTitle = "Account Management";
    firstColumnWidth = 144.61;
    secondColumnWidth = 143.77;
    thirdColumnWidth = 494;
    fourthColumnWidth = 79.95;
    fourthHeader = "Edit";

    body = (
      <CollapseText
        title={editAccountTitle}
        dashboardField={collapseTextField}
        bodyImg=""
      />
    );

    accountData = [
      {
        col1: userContext.userData.edu.user.firstName,
        col2: userContext.userData.edu.user.lastName,
        col3: userContext.userData.emailAddress,
        col4: dataFourthColumn,
      },
    ];
  }

  const [userUpdated, setUserUpdated] = useState(false);

  useEffect(() => {
    document.title = "My Account | Reading Is Fundamental";
    setSettedAccountData(accountData);
  }, [userUpdated]);

  let columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "col1",
        width: firstColumnWidth,
      },
      {
        Header: "Last Name",
        accessor: "col2",
        width: secondColumnWidth,
      },
      {
        Header: "Email",
        accessor: "col3",
        width: thirdColumnWidth,
      },
      {
        Header: fourthHeader,
        accessor: "col4",
        width: fourthColumnWidth,
      },
    ],
    []
  );

  let data = React.useMemo(() => settedAccountData, [settedAccountData, userUpdated]);

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleEdit = () => {
    setUserUpdated(true);
    handleClose();
  };

  const handleModal = (modal: string) => {
    setTeacherAdded(false);
    setErrorDesignate(false);
    setUserUpdated(false);

    switch (modal) {
      case "manage-account-modal":
        setModal({
          show: true,
          class: "edit-account-modal",
          onClick: handleClose,
          header: <h1>{editAccountTitle}</h1>,
          body: (
            <EditMyAccountModal
              rol={props.rol}
              onClick={handleClose}
              onSubmit={handleEdit}
            />
          ),
        });
        break;
      case "designate-admin-modal":
        setModal({
          show: true,
          class: "designate-admin-modal",
          onClick: handleClose,
          header: <h1>Designate Admin</h1>,
          body: (
            <DesignateAdmin onClick={handleClose} changeModal={handleModal} />
          ),
        });
        break;
      case "add-teacher-modal":
        setModal({
          show: true,
          class: "add-teacher-modal",
          onClick: handleClose,
          header: <h1>Add Teacher</h1>,
          body: (
            <ManageTeacherModal
              modalType="add"
              onClick={handleClose}
              onSubmit={handleSubscription}
              teacherData=""
              alsoAdmin={false}
            />
          ),
        });
        break;
      case "error-designate":
        setErrorDesignate(true);
        break;
    }
  };

  const handleSubscription = (from: string) => {
    setTeacherAdded(true);
    handleClose();
    userContext.setFormProgress(25);

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${userContext.token}`,
      },
    };

    if (from === "add-teacher") {
      getTeachers(requestConfig, "edu").then((response: any) => {
        userContext.setFormProgress(100);
        userContext.saveTeachers(
          response,
          userContext.userData.edu.user.relatedAccountId,
          "admin"
        );
      });
    }

    userContext.setFormProgress(100);
  };

  return (
    <Dashboard rol={props.rol}>
      <div className="account-wrapper">
        {body}
        {userUpdated && (
          <div className="text-success-dashboards success-edit-user px-3">
            <TextSuccess text={"Your changes were saved."} />
          </div>
        )}
        <div className={"manage-account-table"}>
          <Table data={data} columns={columns} />
        </div>

        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
};

export default MyAccount;
