import React, { useContext, useEffect } from 'react'
import CollapseText from '../../../Common/Collapse'
import Table from '../../../Common/Table'
import Steeper from '../../../../steeper/Steeper'
import { Link, useParams } from 'react-router-dom'
import ModalWindow from '../../../Common/ModalWindow'
import AddStudentsManually from '../../Modals/AddStudentsManually'
import { useState } from 'react'
import Dashboard from '../../../../../pages/dashboard/Dashboard'
import TextSuccess from '../../../../TextSuccess'
import UserContext from '../../../../../context/user'
import DeleteTeacher from '../../../Admin/AdminModals/DeleteTeacher'
import {
  getRosterStudents, getRosterStudentsClever,
  getSchoolReports,
  randomizeRosterPasswords,
} from '../../../../../api/rifService'
import TextError from '../../../../TextError'
import ActivationEmail from '../../../Admin/AdminModals/ActivationEmail'
import CleverContext from '../../../../../context/clever'
import Cookies from 'universal-cookie'
import { DEFAULT_TEACHER_SKY_THEME, SKYBRARY_CLEVER_TOKEN } from '../../../../../utils/constants'
import { isCleverTheme } from "../../../../../utils/helpers";
import Collapse from "../../../Common/Collapse";
import PopUpModal from "../../Modals/PopUpModal";

let controller = new AbortController()
const cookies = new Cookies()

function EditClassRoster() {
  const userContext: any = useContext(UserContext)
  const cleverContext: any = useContext(CleverContext)
  const schoolId = userContext.userData.edu.user.rrkSchoolId
  const [rosterIdsArray, setRosterIdsArray]: [string[], any] = useState([])
  const [parentEmail, setParentEmail] = useState(0)
  const collapseTextField = ["teachers", cleverContext.isCleverUser() ? "manage_clever_rosters_n" : "manage_rosters_n"];

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  if (cleverContext.isCleverUser()) {
    requestConfig.headers.cleveraccesstoken = cookies.get(SKYBRARY_CLEVER_TOKEN);
  }

  let rosterId: number = 0

  const params = useParams()

  const [randomize, setRandomize] = useState(false)
  const title = cleverContext.isCleverUser() ? 'Students' : 'Add / Edit Students'

  let allStudents: any = []

  const subscriptionInfo = userContext.subscriptions.filter(
    (subscription: any) =>
      subscription.rrkSubscriptionEduId.toString() === params.subscriptionId
  )

  let mappedTableData: any[] = []

  const mapTableData = () => {
    mappedTableData = allStudents.map((student: any, index: number) => {
      if (student.parentId !== null) {
        setRosterIdsArray((rosterIdsArray) => [
          ...rosterIdsArray,
          student.parentId,
        ])
      }
      rosterId = student.rosterId

      if (student.parentEmail !== '') {
        setParentEmail((parentEmail) => parentEmail + 1)
      }

      return !isCleverTheme(userContext, cleverContext) ? {
        col1: index + 1,
        col2: <div className="student-info">{student.firstName}</div>,
        col3: <div className="student-info">{student.lastName}</div>,
        col4: <div className="student-info">{student.userName}</div>,
        col5: (
          <>
            {student.passType === 'icon' ? (
              <div className="pass-cell icons-selected">
                <span
                  className={`icon value value-${student.cleanPass[0]} icon-selected`}
                ></span>
                {student.cleanPass[1] !== undefined && (
                  <span
                    className={`icon value value-${student.cleanPass[1]} icon-selected`}
                  ></span>
                )}
              </div>
            ) : (
              student.cleanPass
            )}
          </>
        ),
        col6: (
          <div className="student-info parent-email">{student.parentEmail}</div>
        ),
        col7: student.parentEmail !== '' && (
          <>
            {student.emailAction === 'send' && (
              <div className="send-email">
                <button
                  onClick={() =>
                    handleModal(
                      'activation-email-modal',
                      student.rosterId,
                      'single',
                      student.parentId,
                      `${student.firstName} ${student.lastName}`
                    )
                  }
                  type="button"
                  className="send-button send-parent-email"
                >
                  Send Email
                </button>
              </div>
            )}
            {student.emailAction !== 'send' && (
              <div className="resend-email">
                <span className="email-sent">Email Sent</span>
                <button
                  type="button"
                  className="send-button"
                  onClick={() =>
                    handleModal(
                      'activation-email-modal',
                      student.rosterId,
                      'single',
                      student.parentId,
                      `${student.firstName} ${student.lastName}`
                    )
                  }
                >
                  Resend Email
                </button>
              </div>
            )}
          </>
        ),
        col8: (
          <div className="edit-student-options">
            <div className="edit-student">
              <button
                onClick={() => {
                  handleModal('edit-student', student)
                }}
                className="btn btn-table-di-serria"
                type="button"
              >
                Edit
              </button>
            </div>
            <div className="delete-student">
              <button
                className="btn"
                type="button"
                onClick={() => handleModal('delete-single', student.rosterId)}
              >
                Delete
              </button>
            </div>
          </div>
        ),
      } : {
        col1: <div className="student-info">{student.firstName}</div>,
        col2: <div className="student-info">{student.lastName}</div>,
        col3: <div className="student-info">{student.userName}</div>,
        col4: <div className="student-info parent-email">{student.parentEmail}</div>,
      }
    })
    setDataTable(mappedTableData)
  }
  const handleCancel = () => {
    controller.abort()
    controller = new AbortController()
    userContext.setFormProgress(0)
  }

  const randomizePassword = async () => {
    setRandomize(false)
    userContext.setFormProgress(50)
    const response = await randomizeRosterPasswords(
      requestConfig,
      params.subscriptionId
    )
    if (response) {
      userContext.setFormProgress(75)
      getRosterStudents(
        requestConfig,
        schoolId,
        subscriptionInfo[0].rrkSubscriptionEduId
      ).then((response) => {
        userContext.setFormProgress(100)
        allStudents = response
        mapTableData()
      })
    } else {
      userContext.setFormProgress(100)
      setRandomize(true)
    }
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = cleverContext.isCleverUser() ? 'Students' : 'Add / Edit Students'
    userContext.setFormProgress(50)
    setIsLoading(true)
    if (cleverContext.isCleverUser()) {
      getRosterStudentsClever(
        requestConfig,
        subscriptionInfo[0].internalSectionId
      ).then((response) => {
        console.log('get roster students clever')
        setIsLoading(false)
        userContext.setFormProgress(100)
        allStudents = response
        mapTableData()
      })
    } else {
      getRosterStudents(
        requestConfig,
        schoolId,
        subscriptionInfo[0].rrkSubscriptionEduId
      ).then((response) => {
        console.log('get roster students classic')
        setIsLoading(false)
        userContext.setFormProgress(100)
        allStudents = response
        mapTableData()
      })
    }
    return () => {
      handleCancel()
    }
  }, [])

  const items = [
    {
      step: 1,
      text: 'Teacher Dashboard',
      link: '/teachers/dashboard',
    },
    {
      step: 2,
      text: 'Class Roster',
      link: '/teachers/manage/rosters',
    },
    {
      step: 3,
      text: 'Edit Class Roster',
      link: `/teachers/manage/rosters/${params.subscriptionId}`,
    },
  ]

  const generateColumns = (extraColumns = []) => {
    const baseColumns = !isCleverTheme(userContext, cleverContext) ? [
        {
          Header: '#',
          accessor: 'col1',
          width: 43,
        },
        {
          Header: 'First Name',
          accessor: 'col2',
          width: 122,
        },
        {
          Header: 'Last Name',
          accessor: 'col3',
          width: 122,
        },
        {
          Header: 'User Name',
          accessor: 'col4',
          width: 122,
        },
      ] :
      [
        {
          Header: 'First Name',
          accessor: 'col1',
          width: 226.5,
        },
        {
          Header: 'Last Name',
          accessor: 'col2',
          width: 226.5,
        },
        {
          Header: 'User Name',
          accessor: 'col3',
          width: 226.5,
        },
        {
          Header: 'Parent Email',
          accessor: 'col4',
          width: 226.5,
        },
      ];

    const dynamicColumns = extraColumns.map(column => {
      return {
        Header: column.header,
        accessor: column.accessor,
        width: 122,
      };
    });

    return [...baseColumns, ...dynamicColumns];
  };

  const columns = React.useMemo(
    () =>
      generateColumns([
        {
          header: (
            <div className="th-wrapper">
              Password
              <button
                type="button"
                className="th-link randomize btn-spinner-indicator"
                onClick={randomizePassword}
                disabled={!(parentEmail > 0)}
              >
                Randomize
              </button>
            </div>
          ),
          accessor: 'col5',
        },
        {
          header: (
            <div className="th-wrapper">
              {parentEmail > 0 ? (
                <Link
                  to="#"
                  onClick={() =>
                    handleModal(
                      'activation-email-modal',
                      rosterId,
                      'all',
                      rosterIdsArray,
                      ''
                    )
                  }
                  className="th-link send-all"
                >
                  Send all
                </Link>
              ) : (
                <div className="th-link send-all disabled">Send all</div>
              )}
            </div>
          ),
          accessor: 'col7',
        },
        {
          header: '',
          accessor: 'col8',
        },
      ]),
    [parentEmail]
  );

  const cleverColumns = React.useMemo(() => generateColumns(), [parentEmail]);
  const columnsToUse = !cleverContext.isCleverUser() ? columns : cleverColumns;

  const [dataTable, setDataTable] = useState(mappedTableData)

  const data = React.useMemo(() => dataTable, [dataTable])

  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (data.length === 35) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }, [dataTable])

  const [modal, setModal] = useState({
    show: false,
    class: '',
    onClick: (success: boolean) => {
    },
    header: <div/>,
    body: <div/>,
  })

  const [successMessageAdd, setSuccessMessageAdd] = useState(false)
  const [successMessageEdit, setSuccessMessageEdit] = useState(false)

  const handleClose = () => {
    setModal({
      show: false,
      class: '',
      onClick: () => {
      },
      header: <div/>,
      body: <div/>,
    })
  }

  const handleStudent = (success: boolean, form: string) => {
    userContext.setFormProgress(50)
    if (success) {
      if (form === 'add') {
        setSuccessMessageAdd(true)
      } else if (form === 'edit') {
        setSuccessMessageEdit(true)
      }
    }
    getRosterStudents(
      requestConfig,
      schoolId,
      subscriptionInfo[0].rrkSubscriptionEduId
    ).then((response) => {
      console.log('get roster students edit')
      setRosterIdsArray([])
      allStudents = response
      mapTableData()
    })
    getSchoolReports(requestConfig, schoolId).then(
      (response) => {
        userContext.saveSchoolReports(response)
      }
    )
    userContext.setFormProgress(100)
    setParentEmail(0)
    handleClose()
  }

  const handleModal = (
    modal: string,
    rosterId: number,
    targets: string,
    parentsIds: string[],
    fullName: string
  ) => {
    setSuccessMessageAdd(false)
    setSuccessMessageEdit(false)
    switch (modal) {
      case 'add-student':
        setModal({
          show: true,
          class: 'add-student-manually-modal',
          onClick: handleClose,
          header: <h1>Add Student</h1>,
          body: (
            <AddStudentsManually
              onClick={handleStudent}
              teacherId={subscriptionInfo[0].teacherId}
              subscriptionId={subscriptionInfo[0].rrkSubscriptionEduId}
              studentInfo={[]}
              type="add"
            />
          ),
        })
        break
      case 'edit-student':
        setModal({
          show: true,
          class: 'add-student-manually-modal',
          onClick: handleClose,
          header: <h1>Edit Student</h1>,
          body: (
            <AddStudentsManually
              onClick={handleStudent}
              teacherId={subscriptionInfo[0].teacherId}
              subscriptionId={subscriptionInfo[0].rrkSubscriptionEduId}
              studentInfo={rosterId}
              type="edit"
            />
          ),
        })
        break
      case 'delete-all':
        setModal({
          show: true,
          class: 'delete-teacher-modal',
          onClick: handleClose,
          header: <></>,
          body: (
            <DeleteTeacher
              onClick={handleStudent}
              from="class-roster-all"
              id={{
                schoolId: schoolId,
                eduSubId: subscriptionInfo[0].rrkSubscriptionEduId,
              }}
            />
          ),
        })
        break
      case 'delete-single':
        setModal({
          show: true,
          class: 'delete-teacher-modal',
          onClick: handleClose,
          header: <></>,
          body: (
            <DeleteTeacher
              onClick={handleStudent}
              from="class-roster-single"
              id={{
                schoolId: schoolId,
                eduSubId: subscriptionInfo[0].rrkSubscriptionEduId,
                rosterId: rosterId,
              }}
            />
          ),
        })
        break
      case 'max-students':
        setModal({
          show: true,
          class: 'delete-teacher-modal',
          onClick: handleClose,
          header: <></>,
          body: (
            <DeleteTeacher
              onClick={handleClose}
              from="max-students-teachers"
              id={{
                schoolId: schoolId,
                eduSubId: subscriptionInfo[0].rrkSubscriptionEduId,
                rosterId: rosterId,
              }}
            />
          ),
        })
        break
      case 'activation-email-modal':
        setModal({
          show: true,
          class: 'activation-email-modal',
          onClick: handleClose,
          header: <h1></h1>,
          body: (
            <ActivationEmail
              onClick={handleStudent}
              targets={targets}
              id={rosterId}
              parentsIds={parentsIds}
              from="roster"
              subscriptionId={params.subscriptionId}
              toName={fullName}
            />
          ),
        })
        break
    }
  }

  return (
    <Dashboard rol="teacher">
      <div className="edit-class-roster-wrapper">
        {
          !isCleverTheme(userContext, cleverContext) ? (
              <>
                <Steeper steps={items} activeStep={3} steeperType="dashboard"/>
                <CollapseText
                  title={title}
                  dashboardField={collapseTextField}
                  bodyImg=""
                />
              </>
            )
            :
            (
              <>
                <PopUpModal title={DEFAULT_TEACHER_SKY_THEME.editRosterDashboard.title}
                            body={DEFAULT_TEACHER_SKY_THEME.editRosterDashboard.body}
                            modalClass={'edit-roster-modal'}
                />
                <h1 className="title">Student Information</h1>
              </>
            )
        }
        <div className="edit-class-roster-table">
          {successMessageAdd && (
            <div className="text-success-dashboards">
              <TextSuccess text={'Student Added'}/>
            </div>
          )}
          {successMessageEdit && (
            <div className="text-success-dashboards">
              <TextSuccess text={'Student Updated'}/>
            </div>
          )}
          {randomize && (
            <div className="py-2">
              <TextError
                wrapper="div"
                text="There was an error, We were not able to randomize your rosters passwords, please try again"
              />
            </div>
          )}
          <div className="main-header user-classroster edit-class-roster-title">
            <span className="d-inline-block" tabIndex={0} data-bs-toggle="tooltip" data-bs-animation="false" title={subscriptionInfo[0].className}>
              <span className="class-roster-title" >
                {subscriptionInfo[0].className}
              </span>
            </span>
            {!cleverContext.isCleverUser() ? (
                <span className="actions">
              {data.length > 1 && (
                <Link
                  to=""
                  type="button"
                  className="btn btn-table-mojo add-student-button"
                  onClick={() => handleModal('delete-all', 0, '', [], '')}
                >
                  Delete All
                </Link>
              )}
                  {!cleverContext.isCleverUser() && (
                    <Link
                      to=""
                      type="button"
                      className={`btn btn-table-brandy-punch add-student-button ${
                        buttonDisabled && 'disabled-add-student'
                      }`}
                      onClick={() => {
                        buttonDisabled
                          ? handleModal('max-students', 0, '', [], '')
                          : handleModal('add-student', 0, '', [], '')
                      }}
                    >
                      Add Student
                    </Link>
                  )}
                  {!isCleverTheme(userContext, cleverContext) &&
                  <Link
                    to="/teachers/manage/rosters"
                    type="button"
                    className="btn btn-table-brandy-punch done-button"
                  >
                    Done
                  </Link>
                  }
                 </span>
              )
              :
              (
                <Link
                  to={`/teachers/manage/rosters`}
                  className={`btn btn-table-moderate-green no-margins`}
                >
                  GO BACK
                </Link>
              )
            }
          </div>
          <Table data={data} columns={columnsToUse} modalClass='invert-colors'/>
        </div>

        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  )
}

export default EditClassRoster
