import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import ModalWindow from "../../../Common/ModalWindow";
import CurriculumResourcesModal from "../../Modals/CurriculumResources";
import UserContext from "../../../../../context/user";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";

function Curriculum(props: {
  title: string;
  description: string;
  imageUrl: string;
  unit: string;
  file: string;
  fileDownload: string;
  inTrial: boolean;
}) {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  let cleverModalClass;
  if (isCleverTheme(userContext, cleverContext)) {
    cleverModalClass = "sky-curriculum-resource-modal curriculum-item"
  } else {
    cleverModalClass = "curriculum-resource-modal"
  }

  const handleModal = () => {
    setModal({
      show: true,
      class: `${cleverModalClass} ${!props.inTrial && userContext.isTrial ? "isTrial" : ""}`,
      onClick: handleClose,
      header: <h1>{props.title}</h1>,
      body: (
        <CurriculumResourcesModal
          imageUrl={props.imageUrl}
          title=""
          description={props.description}
          file={props.file}
          fileDownload={props.fileDownload}
          isTrial={!props.inTrial && userContext.isTrial}
        />
      ),
    });
  };

  return (
    <div
      className={`curriculum-wrapper ${
        !props.inTrial && userContext.isTrial ? "not-available" : ""
      }`}
    >
      <div className="image">
        <Link to="">
          <img src={props.imageUrl} alt="" onClick={() => handleModal()} />
        </Link>
      </div>
      <label className="curriculum-unit">{props.unit}</label>
      <label className="curriculum-label" onClick={() => handleModal()}>
        {props.title}
        <Link to=""></Link>
      </label>

      <ModalWindow
        show={modal.show}
        class={modal.class}
        onClick={modal.onClick}
        header={modal.header}
        body={modal.body}
      />
    </div>
  );
}

export default Curriculum;
