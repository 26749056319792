import { LoadingSpinner } from "@components/spinners/LoadingSpinner";
import { Sort } from "@customTypes/enums/sortEnum";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTable, useBlockLayout, useResizeColumns } from "react-table";

interface PaginatedTableProps {
  data: any;
  columns: any;
  modalClass: string;
  sortArrows: boolean;
  page: number;
  handleChangePage: (page: number) => void;
  handleSortSelected?: (sortBy: string, sortType?: Sort) => void;
  lastPage: number;
  btnStyle?: string;
  isTableLoading?: boolean;
  setIsTableLoading?: (value: boolean) => void;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  data,
  columns,
  modalClass,
  sortArrows,
  page,
  handleChangePage,
  handleSortSelected,
  lastPage,
  btnStyle = "",
  isTableLoading,
  setIsTableLoading,
}) => {
  const [sortType, setSortType] = useState<Sort>(Sort.ASCENDING);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },

        manualPagination: true,
      },
      useBlockLayout,
      useResizeColumns,
    );

  let actualPage = page;
  let prevClassName = "invisible";
  let nextClassName = "invisible";
  let numberClassName = "invisible";

  if (actualPage > 1) {
    prevClassName = "visible";
  }

  if (actualPage < lastPage) {
    nextClassName = "visible";
  }

  if (lastPage > 1) {
    numberClassName = "page-number";
  }

  if (isTableLoading) {
    prevClassName += " disabled";
    nextClassName += " disabled";
  }

  const handleSortClick = (columnSlug: any) => {
    if (setIsTableLoading) {
      setIsTableLoading(true);
    }
    if (handleSortSelected) {
      handleSortSelected(columnSlug, sortType);

      if (sortType === Sort.ASCENDING) {
        setSortType(Sort.DESCENDING);
      } else {
        setSortType(Sort.ASCENDING);
      }
    } else {
      return undefined;
    }
  };

  const handlePage = (to: string) => {
    if (setIsTableLoading) {
      setIsTableLoading(true);
    }
    if (to === "prev") {
      actualPage--;
    } else {
      actualPage++;
    }
    handleChangePage(actualPage);
  };

  return (
    <>
      <table {...getTableProps()} className={`edu-table ${modalClass}`}>
        {isTableLoading && <LoadingSpinner />}
        <thead className="table-header">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                width: "100%",
                opacity: isTableLoading ? "0.6" : "1",
              }}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {sortArrows && column.Header !== "#" && (
                    <button
                      className="sort-arrow-buttons"
                      onClick={() => handleSortClick(column.slug)}
                      disabled={isTableLoading ? true : false}
                    >
                      <svg
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 14L10.2631 8H0.73686L5.5 14Z"
                          fill="#5B6774"
                        />
                        <path
                          d="M5.5 0L10.2631 6H0.73686L5.5 0Z"
                          fill="#5B6774"
                        />
                      </svg>
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="table-body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  width: "100%",
                  opacity: isTableLoading ? "0.6" : "1",
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={`pagination ${btnStyle}`}>
        <button
          disabled={isTableLoading ? true : false}
          className={prevClassName}
          onClick={() => handlePage("prev")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 6.34919V17.6508C19 19.9936 16.4347 21.4323 14.4357 20.2107L5.18884 14.5599C3.27464 13.3901 3.27464 10.61 5.18884 9.44018L14.4356 3.78934C16.4347 2.5677 19 4.0064 19 6.34919Z"
              fill="#184378"
              stroke="#184378"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <span className={numberClassName}>{page}</span>
        <button
          disabled={isTableLoading ? true : false}
          className={nextClassName}
          onClick={() => handlePage("next")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 6.34919V17.6508C5 19.9936 7.56529 21.4323 9.56435 20.2107L18.8112 14.5599C20.7254 13.3901 20.7254 10.61 18.8112 9.44018L9.56435 3.78934C7.56529 2.5677 5 4.0064 5 6.34919Z"
              fill="#184378"
              stroke="#184378"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default PaginatedTable;
