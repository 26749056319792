import { useContext, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";
import ModalWindow from "../../../Common/ModalWindow";
import SneakPeak from "../../Modals/SneakPeak";
import playImg from "../../../../../images/icons/play_icon_big.png";
import ExtraInfo from "./ExtraInfo";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";
import {useLocation} from "react-router-dom";
import CleverContext from "../../../../../context/clever";

function LargeWidgetFeaturedContent() {
  const dashboardContext: any = useContext(DashboardContext);

  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {
    },
    header: <div/>,
    body: <div/>,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {
      },
      header: <div/>,
      body: <div/>,
    });
  };

  const handleModal = (id: number, type: string) => {
    let bodySet: any;
    if (type === "video") {
      bodySet = (
        <SneakPeak
          thumb={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].keyframe
          }
          description={`${dashboardContext.information.teachers.featured_content_large_widget[
            id
            ].description.substring(0, 196)}...`}
          listeningLevel={null}
          genre={null}
          readingLevel={null}
          type="video"
          vimeoId={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].vimeoId
          }
          watch={false}
        />
      );
    } else {
      bodySet = (
        <SneakPeak
          thumb={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].thumb
          }
          description={`${dashboardContext.information.teachers.featured_content_large_widget[
            id
            ].desc.substring(0, 296)}...`}
          listeningLevel={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].listeningLevel
          }
          genre={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].genre.name
          }
          readingLevel={
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].readingLevel
          }
          type="book"
          vimeoId=""
          watch={false}
        />
      );
    }
    setModal({
      show: true,
      class: "sneak-peak-modal",
      onClick: handleClose,
      header: (
        <h1>
          {
            dashboardContext.information.teachers.featured_content_large_widget[
              id
              ].title
          }
        </h1>
      ),
      body: bodySet,
    });
  };

  const content =
    dashboardContext.information.teachers.featured_content_large_widget.map(
      (featured: any, index: number) => {
        if (featured.hasOwnProperty("videoId")) {
          return (
            <div key={index} className="new-content-image-container">
              <button type="button" onClick={() => handleModal(index, "video")}>
                <div className="thumbnail">
                  <div className="video">
                    <img className="play" src={playImg} alt="play"/>
                    <img
                      className="featured_image"
                      src={featured.keyframe}
                      alt="first_featured"
                    />
                  </div>
                  <label>{featured.title}</label>
                </div>
              </button>
            </div>
          );
        } else if (featured.hasOwnProperty("bookId")) {
          return (
            <div key={index} className="new-content-image-container">
              <button type="button" onClick={() => handleModal(index, "book")}>
                <div className="thumbnail">
                  <img
                    className="featured_image"
                    src={featured.thumb}
                    alt="first_featured"
                  />
                  <label>{featured.title}</label>
                </div>
              </button>
            </div>
          );
        } else {
          return (
            <div key={index} className="new-content-image-container">
              <button type="button">
                <div className="thumbnail">
                  <img
                    className="featured_image"
                    src={""}
                    alt="first_featured"
                  />
                  <label>{""}</label>
                </div>
              </button>
            </div>
          );
        }
      }
    );

  return (
    <div className="new-content edu-large-widget-featured-content white-container">
      {!isCleverTheme(userContext, cleverContext) ? (
        <>
          <div className="new-content-header">
            <label className="new-content-title">
              Check out this month's featured content:
            </label>
          </div>
          <div className="new-content-container">{content}</div>
          <ModalWindow
            show={modal.show}
            class={modal.class}
            onClick={modal.onClick}
            header={modal.header}
            body={modal.body}
          />
        </>
      ) : (
        <ExtraInfo />
      )}
    </div>
  );



}

export default LargeWidgetFeaturedContent;
