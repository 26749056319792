import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSchoolinfo } from "../../../../../api/rifService";
import UserContext from "../../../../../context/user";
import eduLogo from "../../../../../images/edu_bw_logo.png";

const StudentPasswords = (props: any) => {
  const userContext: any = useContext(UserContext);
  const params = useParams();
  const [schoolName, setSchoolName] = useState("");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  useEffect(() => {
    document.title =
      "Skybrary for School Classroom Roster | Reading Is Fundamental";
    getSchoolName();
  }, []);

  useEffect(() => {
    if (schoolName !== "") {
      mapStudents();
    }
  }, [schoolName]);

  const classroom = userContext.subscriptions.filter(
    (subscription: any) =>
      subscription.rrkSubscriptionEduId === Number(params.classId)
  );

  const getSchoolName = async () => {
    const response = await getSchoolinfo(requestConfig, classroom[0].schoolId);
    setSchoolName(response.data.name);
  };

  let mappedTableData = [];
  const [students, setStudents] = useState([]);
  const mapStudents = () => {
    mappedTableData = props.students.map((student: any) => {
      return (
        <div key={student.rosterId} className="col-3 student-container column-end">
          <div className="student-password">
            <div className="header-content">
              <div className="header-title title">parents:</div>
              <p>
                Access a digital library full of engaging books and educational
                videos here https://school.skybrary.org/stu
                <br />
                dent/classroom/access
              </p>
            </div>
            <div className="main-content">
              <div className="student-name data-content" title="Student Name">
                {`${student.firstName} ${student.lastName}`}
              </div>
              <div className="class-name data-content" title="Class Name">
                {classroom[0].className}
              </div>
              <div className="teacher-name data-content" title="Teacher Name">
                {`${userContext.userData.edu.user.firstName} ${userContext.userData.edu.user.lastName}`}
              </div>
              <div className="school-name data-content" title="School Name">
                {schoolName}
              </div>

              <br />

              <div className="code-title title button">
                <div className="cc-button info-img" />
              </div>
              <div className="username data-content" title="Classroom Code">
                {classroom[0].classroomCode}
              </div>

              <div className="username-title title button">
                <div className="un-button info-img" />
              </div>
              <div className="username data-content" title="Username">
                {student.userName}
              </div>

              <div className="password-title title button">
                <div className="pw-button info-img" />
              </div>
              {student.passType === "icon" ? (
                <div className="password data-content" title="Password">
                  <span className="pw-container">
                    <div className={`value-${student.cleanPass[0]} pw-img`} />
                    <div className={`value-${student.cleanPass[1]} pw-img`} />
                  </span>
                </div>
              ) : (
                <div className="text-center text-password">
                  {student.cleanPass}
                </div>
              )}
            </div>
            <div className="footer-content">
              <img src={eduLogo} />
              <p className="footer-policy">
                View our privacy policies at www.RIF.org
              </p>
            </div>
            <br />
          </div>
        </div>
      );
    });

    setStudents(mappedTableData);
  };

  useEffect(() => {
    if (students.length > 0){
      props.onLoad();
    }
  }, [students])

  return (
    <div ref={props.refToAsign}>
      <div className="row passwords">{students}</div>
    </div>
  );
};

export default StudentPasswords;
