import { useContext, useEffect, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";

function LargeWidget() {
  const dashboard = useContext(DashboardContext);
  const [largeWidget, setLargeWidget] = useState(<div />);

  const createMarkup = () => {
    return { __html: dashboard.information.teachers.bottom_large_widget };
  };

  useEffect(() => {
    setLargeWidget(<div dangerouslySetInnerHTML={createMarkup()} />);
  }, [dashboard.information]);

  return (
    <div className="content-wrapper edu-large-widget white-container">
      <span>{largeWidget}</span>
    </div>
  );
}

export default LargeWidget;
