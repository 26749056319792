import { Formik, Form, Field, FormikValues } from "formik";
import { useContext, useState } from "react";
import { editSubscription } from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";

let controller = new AbortController();

function EditClassInfo(props: {
  onClick: any;
  subscriptionId: number;
  teacherId: number;
  className: string;
  classCode: any;
}) {
  const userContext = useContext(UserContext);
  let schoolId;

  if (userContext.userData.edu.user.type === "admin") {
    schoolId = userContext.adminTeacher[0].edu.schoolId;
  } else {
    schoolId = userContext.userData.edu.user.rrkSchoolId;
  }
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorUpdating, setErrorUpdating] = useState({
    status: false,
    message: "",
  });

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const validateCode = (value: string) => {
    if (value) {
      if (value.length > 5) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    userContext.setFormProgress(50);
    setButtonDisabled(true);
    setErrorUpdating({ status: false, message: "" });

    editSubscription(
      requestConfig,
      schoolId,
      props.subscriptionId,
      values
    ).then((response) => {
      if (response.status) {
        userContext.setFormProgress(100);
        props.onClick();
      } else {
        userContext.setFormProgress(100);
        setButtonDisabled(false);
        setErrorUpdating({
          status: true,
          message: response.data.response.data.errorMessage,
        });
      }
    });
  };

  return (
    <div className="modal-body-wrapper">
      <p>
        Name your classroom and set up your classroom code here. (Classroom
        codes are used by your students to log into Skybrary.)
      </p>
      <legend className="subtitle-wrapper">
        <span>Roster Info</span>
        <div className="bar"></div>
      </legend>
      {errorUpdating.status && (
        <div className="my-2">
          <TextError
            wrapper="div"
            text={errorUpdating.message}
          />
        </div>
      )}
      <Formik
        initialValues={{
          className: props.className,
          classroomCode: props.classCode,
          teacherId: props.teacherId,
        }}
        onSubmit={handleSubmit}
      >
        <Form className="modal-form roster-info-form">
          <div className="form-item">
            <label htmlFor="className">Class Name</label>
            <Field
              type="text"
              name="className"
              className="form-input edit-class-info-name"
            ></Field>
          </div>

          <div className="form-item">
            <label htmlFor="classroomCode">Classroom Code</label>
            <Field
              type="text"
              name="classroomCode"
              className="form-input edit-class-info-code"
              validate={validateCode}
              maxLength={14}
              placeholder="Minimum 6 characters"
            ></Field>
            <div className="description">
              <p>
                Pick a code that your students will easily be able to type later
              </p>
            </div>
          </div>
          <div className="buttons-wrapper">
            <button
              className="btn-modal btn-mojo-modal"
              type="button"
              onClick={() => props.onClick()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`btn-modal btn-${
                !buttonDisabled ? "red-damask" : "silver"
              } btn-spinner-indicator`}
              disabled={buttonDisabled}
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default EditClassInfo;
