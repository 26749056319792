import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { useContext } from "react";
import UserContext from "../../../../context/user";

const Tabs = (props: { classes: string; to: string; text: string }) => {
  const userContext = useContext(UserContext);

  return (
    <>
      {!userContext.isLoading && (
        <ListGroup.Item as="li" className={props.classes}>
          <Link to={props.to} className="nav-link">
            {props.text}
          </Link>
        </ListGroup.Item>
      )}
    </>
  );
};

export default Tabs;
