import {
  useTable,
  usePagination,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import { useCleverContext } from "@context/clever";
import { LoadingSpinner } from "@components/spinners/LoadingSpinner";
import { OPACITY_FOR_LOADING_STATE } from "@utils/constants";
import PaginationButtons from "@components/Buttons/PaginationButtons";

interface PaginationTableProps {
  data: any;
  columns: any;
  page: number;
  handleChangePage: any;
  lastPage: number;
  isLoading?: boolean;
  setIsLoading?: (state: boolean) => void;
}

const PaginationTable: React.FC<PaginationTableProps> = (props) => {
  const columns = props.columns;
  const data = props.data;
  let currentPage = props.page;
  const cleverContext = useCleverContext();
  const isClever: boolean = cleverContext.isCleverUser();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination: true,
      },
      usePagination,
      useResizeColumns,
      useBlockLayout,
    );

  const handlePage = (to: string) => {
    if (to === "prev") {
      currentPage--;
    } else {
      currentPage++;
    }
    props.handleChangePage(currentPage);
  };

  const handlePrev = () => {
    if (props.setIsLoading) {
      props.setIsLoading(true);
    }
    handlePage("prev");
  };

  const handleNext = () => {
    if (props.setIsLoading) {
      props.setIsLoading(true);
    }
    handlePage("next");
  };

  return (
    <div className="pagination-table-wrapper">
      <table
        style={{
          position: "relative",
          opacity: props.isLoading ? OPACITY_FOR_LOADING_STATE : "1",
        }}
        {...getTableProps()}
      >
        {props.isLoading && <LoadingSpinner />}
        <thead className="table-header">
          {headerGroups.map((headerGroup: any) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="table-body">
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationButtons
        isLoading={props.isLoading}
        isClever={isClever}
        handlePrev={handlePrev}
        handleNext={handleNext}
        lastPage={props.lastPage}
        page={props.page}
      />
    </div>
  );
};

export default PaginationTable;
