import Steeper from "../../../../steeper/Steeper";
import Collapse from "../../../Common/Collapse";
import { Formik, Form, Field } from "formik";
import CurriculumItem from "../Curriculum/CurriculumItem";
import TeacherGuide from "../Curriculum/TeacherGuides";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import { useContext, useEffect, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";
import TextError from "../../../../TextError";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";
import PopUpModal from "../../Modals/PopUpModal";
import { DEFAULT_TEACHER_SKY_THEME } from "../../../../../utils/constants";

function Curriculums() {
  const dashboardContext: any = useContext(DashboardContext);
  const cleverContext = useContext(CleverContext);
  const collapseTextField = [
    "teachers",
    cleverContext.isCleverUser() ? "clever_curriculum" : "curriculum",
  ];

  const [content, setContent] = useState(<></>);

  const userContext: any = useContext(UserContext);

  useEffect(() => {
    document.title = "Curriculum | Reading Is Fundamental";
    getData();
  }, []);

  const [allSections, setAllSections] = useState(<></>);
  const [firstSection, setFirstSection] = useState(<></>);
  const [secondSection, setSecondSection] = useState(<></>);
  const [teachersSection, setTeachersSection] = useState(<></>);
  let allSectionsData;
  let firstSectionData;
  let secondSectionData;
  let teachersSectionData;
  let cleverImageUrl;

  const getData = async () => {
    const curriculumData = dashboardContext.information.curriculum;
    firstSectionData = curriculumData.filter(
      (section: any) => section.grade.name === "K-1"
    );
    secondSectionData = curriculumData.filter(
      (section: any) => section.grade.name === "2-3"
    );
    secondSectionData = secondSectionData.reverse();
    setAllSections(curriculumData);
    setFirstSection(firstSectionData);
    setSecondSection(secondSectionData);

    firstSectionData = firstSectionData.map((data: any, index: number) => {
      if (data.unit !== null) {
        return (
          <CurriculumItem
            title={data.title}
            imageUrl={data.imageFileUrl}
            description={data.body}
            unit={`Unit ${data.unit}: ${data.grade.name}`}
            file={data.curriculumFileUrl}
            fileDownload={data.curriculumFileDownloadUrl}
            inTrial={data.inTrial}
            key={index}
          />
        );
      }
    });

    secondSectionData = secondSectionData.map((data: any, index: number) => {
      if (data.unit !== null) {
        return (
          <CurriculumItem
            title={data.title}
            imageUrl={data.imageFileUrl}
            description={data.body}
            unit={`Unit ${data.unit}: ${data.grade.name}`}
            file={data.curriculumFileUrl}
            fileDownload={data.curriculumFileDownloadUrl}
            inTrial={data.inTrial}
            key={index}
          />
        );
      }
    });

    teachersSectionData = curriculumData.map((data: any, index: number) => {
      if (data.unit === null && data.title !== "Pacing Plan") {
        if (isCleverTheme(userContext, cleverContext)) {
          cleverImageUrl = data.imageFileUrl.replace(".jpg", "-clever.png");
          return (
            <div className="edu-small-widget white-container">
              <TeacherGuide
              title={data.title}
              imageUrl={cleverImageUrl}
              description={data.body}
              file={data.curriculumFileUrl}
              fileDownload={data.curriculumFileDownloadUrl}
              key={index}
            />
            </div>
          );
        } else {
          return (
            <TeacherGuide
              title={data.title}
              imageUrl={data.imageFileUrl}
              description={data.body}
              file={data.curriculumFileUrl}
              fileDownload={data.curriculumFileDownloadUrl}
              key={index}
            />
          );
        }
      }
    });

    allSectionsData = (
      <>
        <label className="grade-section">K-1</label>
        <div className="curriculum-box">{firstSectionData}</div>
        <label className="grade-section">2-3</label>
        <div className="curriculum-box">{secondSectionData}</div>
      </>
    );

    setContent(allSectionsData);
    setAllSections(allSectionsData);
    setFirstSection(firstSectionData);
    setSecondSection(secondSectionData);
    setTeachersSection(teachersSectionData);
  };

  const items = [
    {
      step: 1,
      text: "Teacher Dashboard",
      link: "/teachers/dashboard",
    },
    {
      step: 2,
      text: "Curriculum",
      link: "/teachers/curriculum",
    },
  ];

  const [unitSelected, setUnitSelected] = useState("All");

  const handleUnitChange = (event: any) => {
    setUnitSelected(event.target.value);

    if (event.target.value === "K-1") {
      setContent(
        <>
          <div className="curriculum-box">{firstSection}</div>
        </>
      );
    } else if (event.target.value === "2-3") {
      setContent(
        <>
          <div className="curriculum-box">{secondSection}</div>
        </>
      );
    } else {
      setContent(allSections);
    }
  };

  const hasCurriculumInfo = dashboardContext.information.curriculum.length;

  return (
    <Dashboard rol="teacher">
      <div className="curriculums-wrapper">
        {isCleverTheme(userContext, cleverContext) ? (
          <div className="row home-dashboard">
            <h1>Curriculum</h1>
            <div className="left col-4 p-0">
              <h1>Teacher Guides</h1>
              <div className="info">
                <p>
                  Read the Teacher Guides to help students understand the key
                  concepts and vocabulary and to see corresponding books and
                  videos for each unit.
                </p>
              </div>
              <h1>Themed Units</h1>
              <div className="info">
                <p>
                  Enrich your curriculum with these 40 Themed Units inspired by
                  the books and videos on the library.
                </p>
              </div>
              <div className="filter-units">
                <h4>Filter Units</h4>
                <span className="grades-selected">
                  <Formik initialValues={{ unit: "" }} onSubmit={() => {}}>
                    <Form>
                      <Field
                        as="select"
                        name="units"
                        onChange={handleUnitChange}
                      >
                        <option value="All">All Units</option>
                        <option value="K-1">K-1</option>
                        <option value="2-3">2-3</option>
                      </Field>
                    </Form>
                  </Formik>
                </span>
              </div>
              <PopUpModal
                title={DEFAULT_TEACHER_SKY_THEME.curriculum.title}
                body={DEFAULT_TEACHER_SKY_THEME.curriculum.body}
                modalClass="curriculum-help-modal"
              />
            </div>
            <div className="right col-8 m-0 p-0">
              <div className="row teacher-guides">
              {teachersSection}
              </div>
              <div className="row themed-units">
                <div className="edu-large-widget white-container">
                  <div className="container-fluid curriculums">{content}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Steeper steps={items} activeStep={2} steeperType="dashboard" />
            <div className="container-fluid curriculums pb-2 text-center">
              {!hasCurriculumInfo && (
                <TextError
                  text={
                    "We are having problems to show curriculums, if the problem persists, please contact us."
                  }
                  wrapper={"div"}
                />
              )}
            </div>
            <div className="collapse-text">
              <Collapse
                title="Curriculum"
                dashboardField={collapseTextField}
                bodyImg=""
              />
            </div>
            <div className="curriculum-header collapse-images">
              <Collapse
                title="Teacher's Guides"
                dashboardField=""
                bodyImg={teachersSection}
              />
            </div>
            <div className="curriculum-body pb-0">
              <div className="curriculum-title-wrapper">
                <span className="title">
                  Themed Units: <span className="unit">{unitSelected}</span>
                </span>
                <span className="grades-selected">
                  <Formik initialValues={{ unit: "" }} onSubmit={() => {}}>
                    <Form>
                      <Field
                        as="select"
                        name="units"
                        onChange={handleUnitChange}
                      >
                        <option value="All">All Units</option>
                        <option value="K-1">K-1</option>
                        <option value="2-3">2-3</option>
                      </Field>
                    </Form>
                  </Formik>
                </span>
              </div>
              <div className="container-fluid curriculums">{content}</div>
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
}

export default Curriculums;
