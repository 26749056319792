function Body(props: any) {
  return (
    <div className="dashboard-body-wrapper mx-auto">
      <div className="dashboard-table">
        {props.children}
      </div>
    </div>
  );
}

export default Body;