import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ModalWindow from "../../../Common/ModalWindow";
import SneakPeak from "../../Modals/SneakPeak";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";

interface VideoProps {
  island: string;
  listeningLevel: string | null;
  title: string;
  desc: string;
  keyframe: string;
  vimeoId: string;
}

function Video(props: VideoProps) {
  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = () => {
    setModal({
      show: true,
      class: "sneak-peak-modal video",
      onClick: handleClose,
      header: <h1>{props.title}</h1>,
      body: (
        <SneakPeak
          thumb={props.keyframe}
          description={props.desc}
          listeningLevel={props.listeningLevel}
          genre=""
          readingLevel=""
          type="video"
          vimeoId={props.vimeoId}
          watch={false}
        />
      ),
    });
  };

  const handleWatch = () => {
    setModal({
      show: true,
      class: "sneak-peak-modal video",
      onClick: handleClose,
      header: <h1>{props.title}</h1>,
      body: (
        <SneakPeak
          thumb={props.keyframe}
          description={props.desc}
          listeningLevel={props.listeningLevel}
          genre=""
          readingLevel=""
          type="video"
          vimeoId={props.vimeoId}
          watch={true}
        />
      ),
    });
  };

  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  return (
    <div className="book-wrapper video">
      <div className="thumbnail">
        <div className="play-icon" />
        <img src={props.keyframe} alt="" />
      </div>
      <div className="info">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <div className="first-column">
              <div className="title">{props.title}</div>
              <div className="description">{props.desc}</div>
            </div>
            <div className="second-column">
              <button
                type="button"
                className="button btn btn-sky-video btn-navy-blue"
                onClickCapture={handleWatch}
              >
                Watch
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3.08286V10.9171C1 12.5412 3.01901 13.5385 4.59237 12.6916L11.8701 8.77448C13.3766 7.96358 13.3766 6.03642 11.8701 5.22552L4.59237 1.30838C3.01901 0.461534 1 1.45884 1 3.08286Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="island">{props.island}</div>
            </div>
          </>
        ) : (
          <>
            <div className="detail">
              <div className="title">{props.title}</div>
              <div className="reading">{`${
                props.listeningLevel !== null
                  ? `Listening Level: ${props.listeningLevel}`
                  : ``
              }`}</div>
              <div className="island">{`${
                props.island !== undefined ? `Islands: ${props.island}` : ``
              }`}</div>
            </div>
            <div className="actions">
              <button
                type="button"
                onClick={handleModal}
                className="btn btn-di-serria"
              >
                Sneak Peak
              </button>
              <button
                type="button"
                className="btn btn-brandy-punch"
                onClick={handleWatch}
              >
                Watch
              </button>
            </div>
          </>
        )}
        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </div>
  );
}

export default Video;
