import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../../../context/user";
import LargeWidgetFeaturedContent from "../../../Teacher/TeacherSections/Dashboard/LargeWidgetFeaturedContent";
import { Formik, Field, Form, FormikValues } from "formik";
import { parentText } from "../../../../../utils/constants";
import { useNavigate } from "react-router";
import { differenceInCalendarDays } from "date-fns";
import Dashboard from "../../../../../pages/dashboard/Dashboard";

function ParentDashboard() {
  const userContext = useContext(UserContext)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [leftText, setLeftText] = useState(<></>);
  const [classHash, setClassHash] = useState("");
  const [allReports, setAllReports] = useState([]);
  let nextToExpire = false;
  let allHashes:string[] = [];
  const navigate = useNavigate()

  useEffect(()=>{
    document.title = "Dashboard | Reading Is Fundamental";
    if (userContext.userData) {
      setFirstName(userContext.userData.edu.user.firstName);
      setLastName(userContext.userData.edu.user.lastName);
      setAllReports(userContext.parentReports);
      setLeftText(parentText.normalDashboardText)
      hasSubscriptionToExpire(userContext.parentReports)
      if (!userContext.userData.edu.user.userFirstLogin) {
        setLeftText(parentText.firstLoginDashboardText);
      }
      if(nextToExpire) {
        setLeftText(parentText.nextToExpireDashboardText)
      }
    }
  },[])

  const removeDuplicatedHashes = (arrayOfHashes) => {
    let initialArr = [];
    return arrayOfHashes.reduce(
      (accumulator, item) => {
        return accumulator.includes(item) ? accumulator : [...accumulator, item];
      }, initialArr);
  }

  const submitHandler = () => {
    let classroomHash = allReports[0].classroomHash;
    userContext.saveParentClassroomHashes(classroomHash)
    navigate(`/sigin/parent/${classroomHash}`, {state: {parent: true}});
  }

  const hasSubscriptionToExpire = (reports) => {
    reports.forEach(
      (report) => {
        let expirationDate = new Date(report.classroomExpirationDate * 1000)
        let today = new Date();
        nextToExpire = (differenceInCalendarDays(expirationDate, today) < 10)
      }
    )
  }

  return (
    <Dashboard rol="parent">
      <div className="row home-dashboard">
      <div className="left p-0">
        <h1>{`Welcome Back ${firstName} ${lastName}!`}</h1>
        {leftText}
        <div className="links">
          <div className="active-subscription">
            <h3>Bookmark Creator</h3>
            <p className="description-text">For easy access to Skybrary, select your child's class from the dropdown menu. Click GO TO STUDENT SIGN IN button to open the student sign in page where you can add it to your bookmarks/favorites.</p>
            <Formik initialValues={{ classroom: "" }} onSubmit={submitHandler}>
              <Form>
                <button
                  type="submit"
                  className="btn-red-damask btn-spinner-indicator"
                >
                  Go to student sign in
                </button>
              </Form>
          </Formik>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="table-parent">
          <div className="row">
            <div className="col large-feature">
              <LargeWidgetFeaturedContent />
            </div>
          </div>
        </div>
      </div>
    </div>
    </Dashboard>
  )

}

export default ParentDashboard