import React, { useState, useContext } from "react"
import { useLocation } from "react-router-dom"
import Dashboard from "../../../../../pages/dashboard/Dashboard"
import TextError from "../../../../TextError"
import UserContext from "../../../../../context/user"
import CleverContext from "../../../../../context/clever"
import { isCleverTheme } from "../../../../../utils/helpers"
import PopUpModal from "../../../Teacher/Modals/PopUpModal"
import CardInfo from "../../../../../pages/purchase/card"
import { DEFAULT_DISTRICT_ADMIN_SKY_THEME } from "../../../../../utils/constants"

const TeacherCardInfo = () => {
  const cleverContext = useContext(CleverContext)
  const userContext: any = useContext(UserContext)
  const location: any = useLocation()

  const [emptyError, setEmptyError] = useState(false)

  return (
    <Dashboard rol="teacher">
      <div className="purchase-admin purchase-teacher-gift">
        <PopUpModal
          title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.title}
          body={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.body}
          modalClass={'district-report-modal'}
        />

        {emptyError && (
          <div className={`${!isCleverTheme(userContext, cleverContext) && 'mx-3'} empty-error`}>
            <TextError
              text="You have to select some items to buy"
              wrapper="div"
            />
          </div>
        )}
        <div className="admin-purchase-container">
          <CardInfo page="gift" form="gift"/>
        </div>
      </div>
    </Dashboard>
  );
};

export default TeacherCardInfo;
