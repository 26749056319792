import { useEffect, useState } from "react";
import Table from "../Table";
import React from "react";

const ReadingHistory = (props: any) => {
  let mappedTableData: any[] = [];

  useEffect(() => {
    mapTableData(props.readingHistory);
  }, []);

  const mapTableData = (readingHistory: any) => {
    mappedTableData = readingHistory.map((report: any, index: number) => {
      let readingDate = new Date(
        props.type === "book" ? report.dateRead : report.dateViewed
      );
      let formatedDate = `${readingDate.getMonth() < 10 ? "0" : ""}${
        readingDate.getMonth() + 1
      }/${readingDate.getDate()}/${readingDate.getFullYear()}`;
      let resource: any = {
        col1: <div className={`history-data index`}>{index + 1}.</div>,
        col2: (
          <div className="history-data book-name">
            {props.type === "book" ? report.bookName : report.videoName}
          </div>
        ),
        col3: <div className="history-data">{formatedDate}</div>,
      };

      if (props.type === "book") {
        resource.col4 = (
          <div className="history-data index">
            {Math.ceil(report.totalReadingTime / 60)}
          </div>
        );
      }

      return resource;
    });
    setDataTable(mappedTableData);
  };

  let resourceColumns: any = [
    {
      Header: "",
      accessor: "col1",
      width: 32.03,
    },
    {
      Header: props.type === "book" ? "Book Name" : "Video Name",
      accessor: "col2",
      width: 294.27,
    },
    {
      Header: props.type === "book" ? "Date Read" : "Date Watched",
      accessor: "col3",
      width: 82.22,
    },
  ];

  if (props.type === "book") {
    resourceColumns.push({
      Header: "Total Time",
      accessor: "col4",
      width: 78.55,
    });
  }

  const columns = React.useMemo(() => resourceColumns, []);

  const [dataTable, setDataTable] = useState([]);

  const data = React.useMemo(() => dataTable, [dataTable]);

  return (
    <div className="modal-body-wrapper">
      <div className="info">
        <div className="class-name">{props.className}</div>
        <div className="student-name">
          {props.childName} ({props.booksRead})
        </div>
      </div>
      <div className="reading-history">
        <Table data={data} columns={columns} />
      </div>
    </div>
  );
};

export default ReadingHistory;
