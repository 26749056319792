import { useContext, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";
import ModalWindow from "../../../Common/ModalWindow";
import CurriculumResourcesModal from "../../Modals/CurriculumResources";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";
import CleverContext from "../../../../../context/clever";
import { DEFAULT_TEACHER_SKY_THEME } from '../../../../../utils/constants'

function SmallWidgetSpotlight() {
  const dashboardContext: any = useContext(DashboardContext);

  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const unitSpotlight = dashboardContext.information.curriculum.find(
    (unit: any) => unit.title === 'Best of Me: Growing Up and Getting Along'
  ) ?? {
    id: null,
    title: '',
    body: '',
    unit: '',
    imageFileUrl: '',
    curriculumFileUrl: '',
    curriculumFileDownloadUrl: '',
    grade: {
      id: null,
      name: '',
      description: ''
    }
  };

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {
    },
    header: <div/>,
    body: <div/>,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {
      },
      header: <div/>,
      body: <div/>,
    });
  };

  const handleModal = () => {
    setModal({
      show: true,
      class: "curriculum-resource-modal",
      onClick: handleClose,
      header: <h1>{unitSpotlight.title}</h1>,
      body: (
        <CurriculumResourcesModal
          imageUrl={unitSpotlight.imageFileUrl}
          description={unitSpotlight.body}
          fileDownload={unitSpotlight.curriculumFileDownloadUrl}
          file={unitSpotlight.curriculumFileUrl}
          isTrial={false}
        />
      ),
    });
  };

  return (
    <div className="edu-small-widget-unit-spotlight white-container">
      {
        !isCleverTheme(userContext, cleverContext) ? (
          <>
            {unitSpotlight.id !== null && (
              <div className="spotlight-wrapper">
                <label className="spotlight-title">Unit Spotlight</label>
                <button className="spotlight-button" onClick={handleModal}>
                  <img
                    className="unit-spotlight-img"
                    alt="spotlight"
                    src={unitSpotlight.imageFileUrl}
                  />
                  <p className="unit-spotlight-book-title">{unitSpotlight.title}</p>
                </button>
                <div className="unit-spotlight-footer">
                <span
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: unitSpotlight.body.substring(0, 30),
                  }}
                />
                  <span>{`Unit ${unitSpotlight.unit}: ${unitSpotlight.grade.name}`}</span>
                </div>
              </div>
            )}
            <ModalWindow
              show={modal.show}
              className={modal.class}
              onClick={modal.onClick}
              header={modal.header}
              body={modal.body}
            />
          </>
        ) : (
          <div className="spotlight-wrapper">
            <label className="skybrary-curriculum-title">ABOUT SKYBRARY CURRICULUM</label>
            <span>{DEFAULT_TEACHER_SKY_THEME.aboutSkyCurriculum}</span>
          </div>
        )
      }
    </div>
  );

}

export default SmallWidgetSpotlight;
