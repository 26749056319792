import HeaderInfo from "./Info";
import HeaderSchoolLogo from "./SchoolLogo";

function Header(props: { rol: string }) {
  return (
    <div className="row dashboard-header-wrapper mx-auto">
      <HeaderSchoolLogo />
      <HeaderInfo rol={props.rol} />
    </div>
  );
}

export default Header;
