import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import Table from "../../../Common/Table";
import { Link, useParams } from "react-router-dom";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import UserContext from "../../../../../context/user";
import { getSubscriptionReports, getSubscriptionReportsForClever } from '../../../../../api/rifService'
import ReadingHistoryModal from "../../../Common/Modals/ReadingHistory";
import ModalWindow from "../../../Common/ModalWindow";
import { format } from "date-fns";
import CleverContext from "../../../../../context/clever";
import { SKYBRARY_CLEVER_TOKEN } from '../../../../../utils/constants'
import Cookies from 'universal-cookie'
import { isCleverTheme } from "../../../../../utils/helpers";

let controller = new AbortController();
const cookies = new Cookies()

function ReportDetail() {
  const collapseTextField = ["teachers", "reports_classroom_n"];
  let mappedTableData: any[] = [];

  const userContext = useContext(UserContext);
  let cleverContext = useContext(CleverContext);
  let isCleverUser = cleverContext.isCleverUser();

  const subscriptionId = useParams().subscriptionId;

  const subscriptions: any = userContext.subscriptions;

  const requestConfig = {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const subscription = useMemo(() => {
    return subscriptions.find((sub) => sub.rrkSubscriptionEduId === parseInt(subscriptionId));
  }, [subscriptionId]);
  console.log('name', subscription)

  const [hasStudents, setHasStudents] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [classroomName, setClassroomName] = useState(subscription.className);

  useEffect(() => {
    document.title = "Report Detail | Reading Is Fundamental";
    userContext.setFormProgress(50);
    if (isCleverUser) {
      requestConfig.headers.cleveraccesstoken = cookies.get(SKYBRARY_CLEVER_TOKEN);

      if(subscription) {
        getSubscriptionReportsForClever(requestConfig, subscription.internalSectionId).then(
          (subscriptionReport) => {
            userContext.setFormProgress(100);
            mapTableData(subscriptionReport);
            setIsLoading(false);
          }
        )
      }
    } else {
      console.log('calling subscription report detail from report detail')
      getSubscriptionReports(requestConfig, subscriptionId).then(
        (subscriptionReport) => {
          userContext.setFormProgress(100);
          mapTableData(subscriptionReport);
          setIsLoading(false);
        }
      );
    }
    return () => {
      handleCancel();
    };
  }, []);

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  let totalBooksRead: number = 0;
  let totalTimeReading: number = 0;
  let totalVideosWathched: number = 0;

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = (
    history: object,
    childName: string,
    booksRead: number,
    type: string
  ) => {
    setModal({
      show: true,
      class: "reading-history-modal",
      onClick: handleClose,
      header: (
        <h1 className="title">
          {type === "book" ? "Books List" : "Video List"}
        </h1>
      ),
      body: (
        <ReadingHistoryModal
          onClick={handleClose}
          onSubmit={handleClose}
          readingHistory={history}
          childName={childName}
          booksRead={booksRead}
          className={classroomName}
          type={type}
        />
      ),
    });
  };

  const mapTableData = (subscriptionReport: any) => {
    if (subscriptionReport.length > 0) {
      setHasStudents(true);

      mappedTableData = subscriptionReport.map((report: any) => {
        totalBooksRead = totalBooksRead + report.booksRead;
        totalTimeReading =
          totalTimeReading + Math.ceil(report.totalReadingTime / 60);
        totalVideosWathched = totalVideosWathched + report.videosWatched;

        const getLastFourHistory = (lastFour: any, type: string) => {
          let lastFourResources = lastFour.slice(0, 4);

          let resources: any = lastFourResources.map((history: any) => {
            return (
              <div className="mb-1">
                {type === "books" ? history.bookName : history.videoName}
              </div>
            );
          });

          return resources;
        };

        return {
          col1: (
            <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
              {report.childName}
              {report.booksRead > 0 && (
                isCleverTheme(userContext, cleverContext) ?
                  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.5 0L15.3064 8.2918H24.3882L17.0409 13.4164L19.8473 21.7082L12.5 16.5836L5.15268 21.7082L7.95911 13.4164L0.611794 8.2918H9.69357L12.5 0Z"
                      fill="#800484"/>
                  </svg>
                  :
                  <div className="report-star"/>
              )
              }
            </div>
          ),
          col2: (
            <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
              {report.booksRead}
            </div>
          ),
          col3: (
            <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
              {Math.ceil(report.totalReadingTime / 60)}
            </div>
          ),
          col4:
            report.booksRead > 0 ? (
              isCleverTheme(userContext, cleverContext) ?
                <div className="mb-1">
                  {report.readingHistory[0].bookName}
                </div>
                :
              <>
                <div className="last-four has-read">
                  {getLastFourHistory(report.readingHistory, "books")}
                </div>
                <div className="reading-history-container">
                  <button
                    type="button"
                    onClick={() =>
                      handleModal(
                        report.readingHistory,
                        report.childName,
                        report.booksRead,
                        "book"
                      )
                    }
                    className="reading-history-button"
                  >
                    See All
                  </button>
                </div>
              </>
            ) : (
              <></>
            ),
          col5: (
            <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
              {report.videosWatched}
            </div>
          ),
          col6:
            report.videosWatched > 0 ? (
              isCleverTheme(userContext, cleverContext) ?
                <div className="mb-1">
                  {report.videoViewingHistory[0].videoName}
                </div>
                :
              <>
                <div
                  className={`last-four ${
                    report.booksRead > 0 ? "has-read" : ""
                  }`}
                >
                  <>
                    {getLastFourHistory(report.videoViewingHistory, "videos")}
                  </>
                </div>
                <div className="reading-history-container">
                  <button
                    type="button"
                    onClick={() =>
                      handleModal(
                        report.videoViewingHistory,
                        report.childName,
                        report.videosWatched,
                        "video"
                      )
                    }
                    className="reading-history-button"
                  >
                    See All
                  </button>
                </div>
              </>
            ) : (
              <></>
            ),
          col7: (
            <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
              <>{report.lastUsed ? format(report.lastUsed, 'MM/dd/yyyy') : "Never"}</>
            </div>
          ),
        };
      });

      mappedTableData.push({
        col1: "Totals",
        col2: totalBooksRead,
        col3: totalTimeReading,
        col4: "",
        col5: totalVideosWathched,
        col6: "",
        col7: "",
      });
      setDataTable(mappedTableData);
    } else {
      setClassroomName("No Class");
    }
  };

  const items = [
    {
      step: 1,
      text: "Teacher Dashboard",
      link: "/teachers/dashboard",
    },
    {
      step: 2,
      text: "Reports",
      link: "/teachers/reports",
    },
    {
      step: 3,
      text: "Report Details",
      link: `/teachers/reports/classroom/${subscriptionId}`,
    },
  ];

  const columns = React.useMemo(
    () =>
      [
      {
        Header: "Student",
        accessor: "col1",
        width: 211,
      },
      {
        Header: "Books",
        accessor: "col2",
        width: 72,
      },
      {
        Header: "Time Reading (mins.)",
        accessor: "col3",
        width: 88,
      },
      {
        Header: "Last 4 Books Read",
        accessor: "col4",
        width: 144,
      },
      {
        Header: "Videos",
        accessor: "col5",
        width: 56,
      },
      {
        Header: "Last 4 Videos Watched",
        accessor: "col6",
        width: 144,
      },
      {
        Header: "Last Used",
        accessor: "col7",
        width: 147,
      },
    ]
    ,
    []
  );

  const cleverColumns = React.useMemo(
    () =>
      [
        {
          Header: "STUDENT",
          accessor: "col1",
          width: 182,
        },
        {
          Header: "BOOKS",
          accessor: "col2",
          width: 120,
        },
        {
          Header: "TIME READING (minutes)",
          accessor: "col3",
          width: 120,
        },
        {
          Header: "Last Book Read",
          accessor: "col4",
          width: 182,
        },
        {
          Header: "VIDEOS",
          accessor: "col5",
          width: 120,
        },
        {
          Header: "Last Video Watched",
          accessor: "col6",
          width: 182,
        },
      ]
    ,
    []
  );

  const [dataTable, setDataTable] = useState([]);

  const data = React.useMemo(() => dataTable, [dataTable]);

  return (
    <Dashboard rol="teacher">
      <div className="reports-wrapper">
        {isCleverTheme(userContext, cleverContext) ? (
          <>
            <h1 className="title">Report Details</h1>
          </>
        ) : (
          <>
            <Steeper steps={items} activeStep={3} steeperType="dashboard" />
            <CollapseText
              title="Report Detail"
              dashboardField={collapseTextField}
              bodyImg=""
            />
          </>
        )}
        <div className="teachers-reports-detail-table">
          <div className="main-header teachers-reports-detail-title">
            <span
              tabIndex={0}
              data-bs-toggle="tooltip"
              data-bs-animation="false"
              title={classroomName}
            >
              <span className="reports-detail-title d-inline-block">
                {classroomName}
              </span>
            </span>
            {isCleverTheme(userContext, cleverContext) ? (
              <Link
                to={`/teachers/reports`}
                className={`btn btn-table-moderate-green no-margins`}
              >
                GO BACK
              </Link>
            ) : (
              <span className="actions">
                <Link
                  to="/teachers/reports"
                  type="button"
                  className="btn btn-table-brandy-punch done-button"
                >
                  Done
                </Link>
              </span>
            )}
          </div>
          <Table
            data={data}
            isLoading={isLoading}
            columns={
              isCleverTheme(userContext, cleverContext)
                ? cleverColumns
                : columns
            }
            modalClass={"invert-colors report-details-table"}
          />
        </div>
        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
}

export default ReportDetail;
