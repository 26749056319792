import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../context/user";
import CleverContext from '../../../../context/clever'
import Placeholder from "react-bootstrap/Placeholder";

function Title(props: { rol: string }) {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  const location = useLocation();
  let isHomeTab = location.pathname === "/adm/dashboard";
  const [hasPosterRendered, setHasPosterRendered] = useState<boolean>(false);

  const userData = userContext.userData

  let options = <div className="order-trial-options">Dashboard</div>;
  if (!userContext.isLoading && props.rol !== "") {
    options = (
      <div
        className={`order-trial-options ${
          userContext.isTrial ||
          userContext.userData.edu.user.type === "teacher"
            ? "trial-options"
            : ""
        } ${userContext.isTrial ? "trial-account" : ""}`}
      >
        {props.rol} Dashboard
      </div>
    );

    if (
        userContext.userWithSubscription &&
        userData.type !== "edu-teacher" &&
        !cleverContext.isCleverUser()) {
      if (!userContext.isTrial) {
        options = (
          <Dropdown>
            <Dropdown.Toggle variant="btn dropdown-toggle menu-switch-title">
              {props.rol} Dashboard
              {!cleverContext.isCleverUser() && (
                <span className="arrow"></span>

              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                to="/adm/dashboard"
                as={Link}
              >
                Admin
              </Dropdown.Item>
              <Dropdown.Item
                to="/teachers/dashboard"
                as={Link}
              >
                Teacher
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
    if (userData.type === 'edu-parent') {
      options = (
        <div
          className={`order-trial-options ${
            userContext.userData.edu.user.type === "parent"
              ? "trial-options"
              : ""
          } ${(userContext.isTrial || location.state?.fromTrial) ? "trial-account" : ""}`}
        >
          {props.rol} Dashboard
        </div>
      )
    }
  }

  useEffect(() => {
    if (
      !userData.edu.user.userFirstLogin &&
      userData.type === "edu-admin" &&
      userContext.hasActiveSubscription
    ) {
      setHasPosterRendered(userContext.hasPosterRendered);
    }
  }, [userContext.hasPosterRendered]);

  return (
    <>
      {props.rol != "loading" ? (
        <div className="menu-switch-wrapper justify-content-end">
          {!userContext.isLoading && props.rol !== ""
            ? !userContext.userData.edu.user.userFirstLogin &&
              userContext.userData.type === "edu-admin" &&
              userContext.hasActiveSubscription &&
              isHomeTab &&
              hasPosterRendered && (
                <div className="switch-account flashes">
                  Click to switch account
                  <div className="arrow-right flashes" />
                </div>
              )
            : options}
          {options}
          {userContext.isTrial && (
            <span className="trial_info ms-2">
              : Free Trial
              <Link
                className="btn-purchase btn btn-zest ms-2"
                to="/purchase/from-trial/how"
              >
                Purchase
              </Link>
            </span>
          )}
        </div>
      ) : (
        <div className="w-50 ms-auto">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </div>
      )}
    </>
  );
}

export default Title;
