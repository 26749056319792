import React, { FormEvent, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Modal } from "react-bootstrap";

function PopUpModal(props: { onSubmit: any, typeContent: string, genre:any, listeningLevel:string, readingLevel:string, island:any, unitLevel:any}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlerSubmit = (
    typeContent: string,
    genre: string,
    listeningLevel: string,
    readingLevel: string,
    island: string,
    unitLevel: string
  ) => {
    props.onSubmit(
    {
      typeContent: typeContent,
      genre: Number(genre),
      listeningLevel: listeningLevel,
      readingLevel: readingLevel,
      island: Number(island),
      unitLevel: Number(unitLevel),
    },
  );
    handleClose();
  };

  return (
    <>
      <div className="filter-sky-modal">
        <button onClick={handleShow}>Select filters</button>
      </div>
      <Modal
        className={"library-filter-modal"}
        show={show}
        onHide={handleClose}
        centered
        backdropClassName={"sky-modal-theme"}
      >
        <Modal.Header>
          <Modal.Title>
            <p>SELECT ONE (1) FOR EACH FILTER CATEGORY</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              typeContent: props.typeContent,
              genre: String(props.genre),
              listeningLevel: props.listeningLevel,
              readingLevel: props.readingLevel,
              island: String(props.island),
              unitLevel: String(props.unitLevel),
            }}
            onSubmit={(values) => {
              handlerSubmit(
                values.typeContent,
                values.genre,
                values.listeningLevel,
                values.readingLevel,
                values.island,
                values.unitLevel
              );
            }}
          >
            {({ values }) => (
              <Form>
                <div className="container library-filter-wrapper">
                  <div className="row">
                    <div className="col-3">
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className="filter type-filter"
                      >
                        <h6>Type</h6>
                        <label>
                          <Field type="radio" name="typeContent" value="0" />
                          All Types
                        </label>
                        <label>
                          <Field type="radio" name="typeContent" value="2" />
                          Books
                        </label>
                        <label>
                          <Field type="radio" name="typeContent" value="1" />
                          Videos
                        </label>
                      </div>

                      <div
                        role="group"
                        aria-labelledby="my-radio-group2"
                        className="filter genre-filter"
                      >
                        <h6>Genre</h6>
                        <label>
                          <Field type="radio" name="genre" value="0" />
                          All Genres
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="1" />
                          Biography
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="2" />
                          Concept
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="3" />
                          Fable
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="4" />
                          Fantasy
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="100" />
                          Fiction
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="5" />
                          Folk and Fairy Tale
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="6" />
                          Historical Fiction
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="7" />
                          Narrative Nonfiction
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="200" />
                          Nonfiction
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="8" />
                          Poetry, Verse, & Song
                        </label>
                        <label>
                          <Field type="radio" name="genre" value="9" />
                          Realistic Fiction
                        </label>
                      </div>

                      <div
                        role="group"
                        aria-labelledby="my-radio-group3"
                        className="filter listening-level-filter"
                      >
                        <h6>Listening/Unit levels</h6>
                        <label>
                          <Field type="radio" name="listeningLevel" value="0" />
                          All Levels
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="listeningLevel"
                            value="PreK-K"
                          />
                          PreK-K
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="listeningLevel"
                            value="K-1"
                          />
                          K-1
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="listeningLevel"
                            value="2-3"
                          />
                          2-3
                        </label>
                      </div>
                    </div>

                    <div className="col-3">
                      <div
                        role="group"
                        aria-labelledby="my-radio-group4"
                        className="filter reading-level-filter"
                      >
                        <h6>Supported reading levels</h6>
                        <label>
                          <Field type="radio" name="readingLevel" value="0" />
                          All Supported Reading Levels
                        </label>
                        <label>
                          <Field type="radio" name="readingLevel" value="K" />K
                        </label>
                        <label>
                          <Field type="radio" name="readingLevel" value="1" />1
                        </label>
                        <label>
                          <Field type="radio" name="readingLevel" value="2" />2
                        </label>
                        <label>
                          <Field type="radio" name="readingLevel" value="3" />3
                        </label>
                      </div>

                      <div
                        role="group"
                        aria-labelledby="my-radio-group5"
                        className="filter island-filter"
                      >
                        <h6>Islands</h6>
                        <label>
                          <Field type="radio" name="island" value="-1" />
                          All Islands
                        </label>
                        <label>
                          <Field type="radio" name="island" value="0" />
                          Action, Adventure and Magical Tales
                        </label>
                        <label>
                          <Field type="radio" name="island" value="2" />
                          Animal Kingdom
                        </label>
                        <label>
                          <Field type="radio" name="island" value="1" />
                          Awesome People
                        </label>
                        <label>
                          <Field type="radio" name="island" value="4" />
                          Genius Academy
                        </label>
                        <label>
                          <Field type="radio" name="island" value="3" />
                          My Friends, My Family
                        </label>
                        <label>
                          <Field type="radio" name="island" value="7" />
                          Music Mountain
                        </label>
                        <label>
                          <Field type="radio" name="island" value="5" />
                          National Geographic Kids
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        role="group"
                        aria-labelledby="my-radio-group6"
                        className="filter unit-level-filter"
                      >
                        <div className="col-3">
                          <h6>Unit levels</h6>
                          <label>
                            <Field type="radio" name="unitLevel" value="0" />
                            All Units Levels
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="1" />
                            Off to School
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="2" />
                            Fall into Fall
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="3" />
                            People At Work
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="4" />
                            On the Go
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="5" />
                            Safari Adventure
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="6" />
                            All Kinds of Families
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="7" />
                            Best Of Me
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="8" />
                            Be Great, Create!
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="9" />
                            Staying Strong
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="10" />
                            Winter Wonderland
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="11" />
                            People to Know
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="12" />
                            Into the Forests
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="14" />
                            Fun on the Farm
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="13" />
                            Spring into Spring
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="15" />
                            Insects and Spiders
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="16" />
                            Deep in the Sea
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="17" />
                            Fantastic Fairy Tales
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="18" />
                            Tales of Tricksters
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="19" />
                            Out of This World
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="20" />
                            Pack Your Bags!
                          </label>
                        </div>

                        <div className="col-3">
                          <label>
                            <Field type="radio" name="unitLevel" value="21" />
                            We the People
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="22" />
                            Heroes All Around Us
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="23" />
                            Imagine That
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="24" />
                            Celebrate
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="25" />
                            Animal Habitats
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="26" />
                            Creature Feature
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="27" />
                            Amazing Animals
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="28" />
                            Pick Your Pet
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="29" />
                            Exploring Our Earth
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="20" />
                            Disaster!
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="31" />
                            The Three R's
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="32" />
                            Forces at Work
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="33" />
                            Citizens Count
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="34" />
                            Money Smarts
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="35" />
                            The Human Body
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="36" />
                            Let's Play!
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="37" />
                            Creation Station
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="38" />
                            Fabulous Fables
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="39" />
                            Stories to Savor
                          </label>
                          <label>
                            <Field type="radio" name="unitLevel" value="40" />
                            Poems to Own
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="button btn btn-sky-library btn-persian-green"
                  >
                    Apply
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PopUpModal;
