import logo from "../../../../images/logo-skybrary-school.png";

function SchoolLogo() {
  return (
    <div className="col-3 skybrary-logo-wrapper justify-content-end px-0">
      <img src={logo} alt="" className="skybrary-logo img-fluid"/>
    </div>
  );
}

export default SchoolLogo;
