import Modal from "react-bootstrap/Modal";
import { useContext } from "react";
import CleverContext from "../../../context/clever";
import { isCleverTheme } from "../../../utils/helpers";
import UserContext from "../../../context/user";

function ModalWindow(props: {
  show: boolean;
  onClick: any;
  class: string;
  header: any;
  body: any;
}) {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  
  return (
    <Modal
      show={props.show}
      className={`dashboard-modals ${props.class} ${
        userContext.formProgress > 0 ? "submit-loading" : ""
      }`}
      onHide={() => props.onClick()}
      backdrop={isCleverTheme(userContext, cleverContext)}
      backdropClassName={isCleverTheme(userContext, cleverContext) ? ("sky-modal-theme") : ("backdrop-modal")}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
    </Modal>
  );
}

export default ModalWindow;
