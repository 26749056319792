import { useContext, useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { uploadStudentsState, getTeacherUploadStatus } from "../../../../api/rifService";
import UserContext from "../../../../context/user";

const UploadingCsvFile = (props: any) => {
  const userContext = useContext(UserContext);
  const [message, setMessage] = useState("Bulk is starting");
  const [isCompleted, setIsCompleted] = useState(false);
  const [totalStudents, setTotalStudents] = useState();
  const [progress, setProgress] = useState(0);
  const [showPercentage, setShowPercentage] = useState("d-none");
  const [entries, setEntries] = useState(0);
  const [totalTeachers, setTotalTeachers] = useState(0)
  const [bulkTitle, setBulkTitle] = useState('');
  const [totalJobs, setTotalJobs] = useState(0);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  useEffect(() => {
    if (props.from === 'upload-teacher') {
      setBulkTitle('Bulk Teachers Process')
      getTeacherUploadStatus(
        props.batchId,
        props.schoolId,
        requestConfig
      )
        .then((response) => {
          setIsCompleted(response.data.data.isCompleted);
          setTotalJobs(response.data.data.totalJobs)
          if (response.data.data.message && typeof response.data.data.message != "string") {
            setProgress(Math.trunc(response.data.data.message));
            setTotalTeachers(Math.trunc((response.data.data.message * response.data.data.totalJobs) / 100))
            setMessage(`Completed ${totalTeachers} of ${totalJobs}`)
          }
          if (isCompleted) {
            setProgress(100)
            setTotalJobs(response.data.data.totalJobs)
            setTotalTeachers(response.data.data.totalJobs)
            setMessage(`Completed ${totalJobs} of ${totalJobs}`)
            props.onSubmit(
              response.data.data.message
            )
          } else {
            setEntries(entries + 1);
          }
        })
        .catch((error) => {
          console.log('Unable to load teachers')
        })
    } else {
      setBulkTitle('Bulk Students Process')
      uploadStudentsState(
        requestConfig,
        props.formData,
        props.schoolId,
        props.subscriptionId,
        props.batchId
      )
        .then((response) => {
          setIsCompleted(response.data.isCompleted);
          setTotalStudents(response.data.totalJobs);
          if (response.data.message) {
            setProgress(Math.trunc(response.data.message * 100 / response.data.totalJobs));
          }
          if (isCompleted) {
            setProgress(100);
            setShowPercentage("");
            props.onSubmit(totalStudents);
          } else {
            setEntries(entries + 1);
          }
          setMessage(
            `Completed ${response.data.message} of ${response.data.totalJobs}.`
          );
        })
      }
    }, [entries]);



  return (
    <div className="students-process">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="progress-bar-wrapper">
        <h1 className="progress-bar-title text-center">{bulkTitle}</h1>
        <ProgressBar
          animated
          now={progress}
          variant="custom-progress-color"
          className="csv-progress-bar"
        />
        <div className='d-flex justify-content-between progress-messages'>
          <div className="message">{message}</div>
          {progress > 0 && <p className="message">{progress}%</p>}
        </div>
        <div className={`message complete ${showPercentage}`}>100%</div>
      </div>
    </div>
  );
};

export default UploadingCsvFile;
