import { useContext, useEffect, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";
import UserContext from "../../../../../context/user";
import { isCleverTheme } from "../../../../../utils/helpers";
import CleverContext from "../../../../../context/clever";

function Info(props: { userType: string }) {
  const dashboard = useContext(DashboardContext);
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const [dashboardInfo, setDashboardInfo] = useState(null);

  const createMarkup = () => {
    return {
      __html: dashboard.information.teachers[`start_info_${props.userType}`],
    };
  };

  useEffect(() => {
    if (dashboard.information) {
      setDashboardInfo(<div dangerouslySetInnerHTML={createMarkup()} />);
    }
  }, [dashboard.information, userContext.students]);

  return (
    <>
      <div className="info">{dashboardInfo}</div>
      {isCleverTheme(userContext, cleverContext) && (
        <>
          <h1>{`Tutorials`}</h1>
          <div className="tutorials">
            <span className="icon teacher-dashboard-tutorial"></span>
            <a
              href="https://cf.rrkidz.com/files/downloads/EDU_Tutorial_TeacherDashboard.pdf"
              className="tutorial-label"
              target="_blank"
              rel="noreferrer"
            >
              Teacher Dashboard Tutorial
            </a>
          </div>
          <div className="tutorials">
            <span className="icon classroom-setup-tutorial"></span>
            <a
              href="https://cf.rrkidz.com/files/downloads/EDU_Tutorial_TeacherDashboard.pdf"
              className="tutorial-label"
              target="_blank"
              rel="noreferrer"
            >
              Classroom Setup Tutorial
            </a>
          </div>
        </>
      )}
    </>
  );
}

export default Info;
