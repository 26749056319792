import { Formik, Form, Field } from "formik";
import { useState, useContext, FormEvent } from "react";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";
import FilterSkyModal from "../../Modals/FilterSkyModal";

function Filter(props: {
  disabledButton: any;
  page: number;
  handleSortChange: any;
  onSubmit: any;
  clearLoading: boolean;
}) {
  const [allFilter, setAllFilter] = useState("0");
  const [inputTextValue, setInputTextValue] = useState("");
  const [genre, setGenre] = useState(0);
  const [listeningLevel, setListeningLevel] = useState("0");
  const [readingLevel, setReadingLevel] = useState("0");
  const [sortBy, setSortBy] = useState("alpha");
  const [unitLevel, setUnitLevel] = useState(0);
  const [island, setIsland] = useState(-1);
  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  const handleAllFilter = (event: any) => {
    setAllFilter(event.target.value);
  };

  const handlerChangeText = (event: any) => {
    setInputTextValue(event.target.value);
  };

  const handlerGenre = (event: any) => {
    setGenre(event.target.value);
  };

  const handlerListeningLevel = (event: any) => {
    setListeningLevel(event.target.value);
  };

  const handlerReadingLevel = (event: any) => {
    setReadingLevel(event.target.value);
  };

  const handlerSortBy = (event: any) => {
    setSortBy(event.target.value);
    props.handleSortChange(event.target.value);
  };

  const handlerUnitLevel = (event: any) => {
    setUnitLevel(event.target.value);
  };

  const handlerIsland = (event: any) => {
    setIsland(Number(event.target.value));
  };

  const handlerClear = () => {
    setAllFilter("0");
    setInputTextValue("");
    setGenre(0);
    setListeningLevel("0");
    setReadingLevel("0");
    setSortBy("alpha");
    props.handleSortChange("alpha");
    setUnitLevel(0);
    setIsland(-1);
    props.onSubmit(
      {
        title: "",
        listeningLevel: "0",
        readingLevel: "0",
        genre: 0,
        type: 0,
        unitLevel: 0,
        islandId: -1,
      },
      true
    );
  };

  const handlerSkyModalSubmit = (values: any) => {
    setAllFilter(values.typeContent);
    setGenre(values.genre);
    setListeningLevel(values.listeningLevel);
    setReadingLevel(values.readingLevel);
    setIsland(values.island);
    setUnitLevel(values.unitLevel);

    props.onSubmit(
      {
        title: inputTextValue,
        listeningLevel: values.listeningLevel,
        readingLevel: values.readingLevel,
        genre: Number(values.genre),
        type: Number(values.typeContent),
        unitLevel: Number(values.unitLevel),
        islandId: values.island,
      },
      false
    );
  };

  const handlerSubmit = (
    text: string,
    listeningLevel: string,
    genre: string,
    readingLevel: string
  ) => {
    props.onSubmit(
      {
        title: text,
        listeningLevel: listeningLevel,
        readingLevel: readingLevel,
        genre: Number(genre),
        type: Number(allFilter),
        unitLevel: Number(unitLevel),
        islandId: island,
      },
      false
    );
  };

  return (
    <div className="filter-wrapper">
      {isCleverTheme(userContext, cleverContext) ? (
        <>
          <Formik
            initialValues={{
              searchText: "",
              genre: "",
              listeningLevel: "",
              readingLevel: "",
              sort: "alpha",
            }}
            onSubmit={() => {
              handlerSubmit(
                inputTextValue,
                listeningLevel,
                genre,
                readingLevel
              );
            }}
          >
            <Form>
              <div className="search-bar-wrapper">
                <Field
                  type="text"
                  name="searchText"
                  className="form-input text"
                  onChange={handlerChangeText}
                  value={inputTextValue}
                  disabled={props.disabledButton}
                ></Field>
                <div className="filter-buttons">
                  <button
                    type="submit"
                    className={`btn btn-sky-library button btn-dark-cyan ${
                      !props.clearLoading ? "btn-spinner-indicator" : ""
                    }`}
                    disabled={props.disabledButton}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sky-library button btn-gray-chateau  ${
                      props.clearLoading ? "btn-spinner-indicator" : ""
                    }`}
                    onClick={() => {
                      handlerClear();
                    }}
                    disabled={props.disabledButton}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
          <div className="filter-actions">
            <h4>Filter search</h4>
            <FilterSkyModal
              onSubmit={handlerSkyModalSubmit}
              typeContent={allFilter}
              genre={genre}
              listeningLevel={listeningLevel}
              readingLevel={readingLevel}
              island={island}
              unitLevel={unitLevel}
            />
            <h4>Arrange by</h4>
            <Formik
              initialValues={{ sort: "alpha" }}
              onSubmit={() => {
                handlerSubmit(
                  inputTextValue,
                  listeningLevel,
                  genre,
                  readingLevel
                );
              }}
            >
              <Form>
                <Field
                  as="select"
                  type="submit"
                  name="sort"
                  className="form-input"
                  onChange={handlerSortBy}
                  value={sortBy}
                >
                  <option value="desc">Newest to Oldest</option>
                  <option value="asc">Oldest to Newest</option>
                  <option value="alpha">Alphabetical</option>
                </Field>
              </Form>
            </Formik>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            searchText: "",
            genre: "",
            listeningLevel: "",
            readingLevel: "",
            sort: "alpha",
          }}
          onSubmit={() =>
            handlerSubmit(inputTextValue, listeningLevel, genre, readingLevel)
          }
        >
          <Form>
            <Field
              type="text"
              name="searchText"
              className="form-input text"
              onChange={handlerChangeText}
              value={inputTextValue}
              disabled={props.disabledButton}
            ></Field>

            <Field
              as="select"
              name="booksAndVideos"
              className="form-input"
              onChange={handleAllFilter}
              value={allFilter}
            >
              <option value="0">All Books and Videos</option>
              <option value="2">Books</option>
              <option value="1">Videos</option>
            </Field>

            <div className="buttons-wrapper">
              <button
                type="button"
                className={`btn btn-mojo-modal btn-mojo ${
                  props.clearLoading ? "btn-spinner-indicator" : ""
                }`}
                onClick={() => {
                  handlerClear();
                }}
                disabled={props.disabledButton}
              >
                Clear
              </button>
              <button
                type="submit"
                className={`btn btn-brandy-punch ${
                  !props.clearLoading ? "btn-spinner-indicator" : ""
                }`}
                disabled={props.disabledButton}
              >
                Search
              </button>
            </div>

            <Field
              as="select"
              name="genre"
              className="form-input"
              onChange={handlerGenre}
              value={genre}
            >
              <option value="0">All Genres</option>
              <option value="1">Biography</option>
              <option value="2">Concept</option>
              <option value="3">Fable</option>
              <option value="4">Fantasy</option>
              <option value="100">Fiction</option>
              <option value="5">Folk and Fairy Tale</option>
              <option value="6">Historical Fiction</option>
              <option value="7">Narrative Nonfiction</option>
              <option value="200">Nonfiction</option>
              <option value="8">Poetry, Verse, & Song</option>
              <option value="9">Realistic Fiction</option>
            </Field>

            <Field
              as="select"
              name="listeningLevel"
              className="form-input"
              onChange={handlerListeningLevel}
              value={listeningLevel}
            >
              <option value="0">All Listening Levels</option>
              <option value="PreK-K">PreK-K</option>
              <option value="K-1">K-1</option>
              <option value="2-3">2-3</option>
            </Field>

            <Field
              as="select"
              name="readingLevel"
              className="form-input"
              onChange={handlerReadingLevel}
              value={readingLevel}
            >
              <option value="0">All Supported Reading Levels</option>
              <option value="K">K</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Field>

            <Field
              as="select"
              name="unitLevel"
              className="form-input"
              onChange={handlerUnitLevel}
              value={unitLevel}
            >
              <option value="0">All Units Levels</option>
              <option value="1">Off to School</option>
              <option value="2">Fall into Fall</option>
              <option value="3">People at Work</option>
              <option value="4">On the Go</option>
              <option value="5">Safari Adventure</option>
              <option value="6">All Kinds of Families</option>
              <option value="7">Best Of Me</option>
              <option value="8">Be Great, Create!</option>
              <option value="9">Staying Strong</option>
              <option value="10">Winter Wonderland</option>
              <option value="11">People to Know</option>
              <option value="12">Into the Forests</option>
              <option value="13">Spring into Spring</option>
              <option value="14">Fun on the Farm</option>
              <option value="15">Insects and Spiders</option>
              <option value="16">Deep in the Sea</option>
              <option value="17">Fantastic Fairy Tales</option>
              <option value="18">Tales of Tricksters</option>
              <option value="19">Out of This World</option>
              <option value="20">Pack Your Bags!</option>
              <option value="21">We the People</option>
              <option value="22">Heroes All Around Us</option>
              <option value="23">Imagine That</option>
              <option value="24">Celebrate</option>
              <option value="25">Animal Habitats</option>
              <option value="26">Creature Feature</option>
              <option value="27">Amazing Animals</option>
              <option value="28">Pick Your Pet</option>
              <option value="29">Exploring Our Earth</option>
              <option value="20">Disaster!</option>
              <option value="31">The Three R's</option>
              <option value="32">Forces at Work</option>
              <option value="33">Citizens Count</option>
              <option value="34">Money Smarts</option>
              <option value="35">The Human Body</option>
              <option value="36">Let's Play!</option>
              <option value="37">Creation Station</option>
              <option value="38">Stories to Savor</option>
              <option value="39">Poems to Own</option>
            </Field>

            <Field
              as="select"
              name="island"
              className="form-input"
              onChange={handlerIsland}
              value={island}
            >
              <option value="-1">All Islands</option>
              <option value="0">Action, Adventure and Magical Tales</option>
              <option value="2">Animal Kingdom</option>
              <option value="1">Awesome People</option>
              <option value="4">Genius Academy</option>
              <option value="3">My Friends, My Family</option>
              <option value="7">Music Mountain</option>
              <option value="5">National Geographic Kids</option>
            </Field>

            <Field
              as="select"
              type="submit"
              name="sort"
              className="form-input"
              onChange={handlerSortBy}
              value={sortBy}
            >
              <option value="desc">Newest to Oldest</option>
              <option value="asc">Oldest to Newest</option>
              <option value="alpha">Alphabetical</option>
            </Field>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default Filter;
