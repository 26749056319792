import HeaderTitle from "./Title";
import HeaderOptions from "./Options";
import TeacherHeaderSections from "../../Teacher/Header/TeacherHeaderSections";
import AdminHeaderSections from "../../Admin/Header/AdminHeaderSections";
import ParentHeaderSections from "../../Parent/Header/ParentHeaderSections"

function DashboardInfo(props: {rol: string;}) {
  return (
    <div className="col-9 px-0">
      <HeaderTitle rol={props.rol} />
      <HeaderOptions rol={props.rol} />
      {props.rol === "teacher" && <TeacherHeaderSections /> }
      {props.rol === "admin" && <AdminHeaderSections /> }
      {props.rol === "parent" && <ParentHeaderSections />}
    </div>
  );
}

export default DashboardInfo;
