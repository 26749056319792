import React, { useContext, useEffect, useState} from "react";
import UserContext from "../../../../../context/user";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import Steeper from "../../../../steeper/Steeper";
import CollapseText from "../../../Common/Collapse";
import Table from "../../../Common/Table";
import { format } from 'date-fns'
import ModalWindow from "../../../Common/ModalWindow";
import ReadingHistoryModal from "../../../Common/Modals/ReadingHistory";


let controller = new AbortController();

const ParentReports = () => {
  const userContext = useContext(UserContext);
  const collapseTextField = ["parent", "reports"];
  const [table, setTable] = useState(<></>);
  let allReports:any[] = [];

  const items = [
    {
      step: 1,
      text: "Parents Dashboard",
      link: "/parent/dashboard",
    },
    {
      step: 2,
      text: "Reports",
      link: "/parent/reports",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Student",
        accessor: "col1",
        width: 211,
      },
      {
        Header: "Books",
        accessor: "col2",
        width: 72,
      },
      {
        Header: "Reading Time (mins.)",
        accessor: "col3",
        width: 88,
      },
      {
        Header: "Last 4 Books Read",
        accessor: "col4",
        width: 144,
      },
      {
        Header: "Videos",
        accessor: "col5",
        width: 56,
      },
      {
        Header: "Last 4 Videos Watched",
        accessor: "col6",
        width: 169,
      },
      {
        Header: "Last Used",
        accessor: "col7",
        width: 122,
      },
    ],
    []
  );

  const handleCancel = () => {
    controller.abort();
    controller = new AbortController();
    userContext.setFormProgress(0);
  };

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const handleModal = (
    history: object,
    childName: string,
    booksRead: number,
    className: string,
    type: string
  ) => {
    setModal({
      show: true,
      class: "reading-history-modal",
      onClick: handleClose,
      header: <h1 className="title">{type === "book" ? "Books List" : "Video List"}</h1>,
      body: (
        <ReadingHistoryModal
          onClick={handleClose}
          onSubmit={handleClose}
          readingHistory={history}
          childName={childName}
          booksRead={booksRead}
          className={className}
          type={type}
        />
      ),
    });
  };

  const last4Resources = (resourceHistory: any, resource: string) => {
    let lastResources = resourceHistory.slice(0,4)
    return lastResources.map(
      (history, key) => {
        return (
          <div className="mb-1" key={key}>
            {resource === "books" ? history.bookName : history.videoName}
          </div>
        );
      }
    )
  }

  let renderRostersReportsTable = () => {
    const parentReports = userContext.parentReports;
    parentReports.map(
      (report, key) => {
        let data = [{
          col1: report.childName,
          col2: report.booksRead,
          col3: Math.ceil(report.totalReadingTime/60),
          col4: report.booksRead > 0 ? (
            <>
              <div className={`last-four`}>
                {last4Resources(report.readingHistory, "books")}
              </div>
              <div className="reading-history-container">
                <button
                  type="button"
                  onClick={() =>
                    handleModal(
                      report.readingHistory,
                      report.childName,
                      report.booksRead,
                      report.classroomName,
                      "book"
                    )
                  }
                  className="reading-history-button"
                >
                  See All
                </button>
              </div>
            </>
          ) : (
            <></>
          ),
          col5: report.videosWatched,
          col6: (
            report.videosWatched > 0 ? (
              <>
                <div
                  className={`last-four`}
                >
                  {last4Resources(report.videoViewingHistory, "videos")}
                </div>
                <div className="reading-history-container">
                  <button
                    type="button"
                    onClick={() =>
                      handleModal(
                        report.videoViewingHistory,
                        report.childName,
                        report.videosWatched,
                        report.classroomName,
                        "video"
                      )
                    }
                    className="reading-history-button"
                  >
                    See All
                  </button>
                </div>
              </>
            ) : (
              <></>
            )
          ),
          col7: report.lastUsed ? format(report.lastUsed, 'MM/dd/yyyy') : 'Never',
        }];
        
        allReports.push(
          <div className={`parents-reports-table ${(key === userContext.parentReports.length - 1) ?'last' : ''}`} key={key}>
            <div className="main-header all-classes-user-email">
              {report.classroomName}
            </div>
            <Table data={data} columns={columns} />
          </div>
        );
      }
    )
    setTable(allReports);
  }
    

  useEffect(() => {
    document.title = "Parent Report | Reading Is Fundamental";
    renderRostersReportsTable()
    return () => {
      handleCancel();
    };
  },[userContext.parentReports])


  return (
    <Dashboard rol="parent">
      <div className="reports-wrapper">
        <Steeper steps={items} activeStep={2} steeperType="dashboard" />
        <CollapseText
            title="Parent Report"
            dashboardField={collapseTextField}
            bodyImg=""
        />
        {table}
        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  )
}

export default ParentReports;