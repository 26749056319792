import { Formik, Field, Form, FormikValues, ErrorMessage } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { editUserInfo, userSignIn } from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import TextError from "../../../TextError";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";

function EditMyAccount(props: { rol: String; onClick: any; onSubmit: any }) {
  const userContext: any = useContext(UserContext);
  const navigate = useNavigate();
  const userId = userContext.userData.userId;

  const initialData = {
    firstName: userContext.userData.edu.user.firstName,
    lastName: userContext.userData.edu.user.lastName,
    email: userContext.userData.emailAddress,
  };

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const validate = Yup.object({
    firstName: Yup.string().required("First name field is required."),
    lastName: Yup.string().required("Last name field is required."),
    email: Yup.string()
      .required("Username or email adress field.")
      .email("Email field is required."),
    password: Yup.string()
      .required("Please enter a password.")
      .min(8, "Password is too short, please use 8 or more characters")
      .matches(
        /(?=.*[a-z])/,
        "Password must use one number, capital or a special character"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must use one number, capital or a special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Re-enter Password field is required."),
  });

  let saveButtonText = "";

  if (props.rol === "admin") {
    saveButtonText = "Save Profile";
  } else {
    saveButtonText = "Save";
  }

  const [errorUpdate, setErrorUpdate] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    userContext.setFormProgress(50);
    setErrorUpdate(false);
    setButtonDisabled(true);
    const emailLowercase = values.email.toLocaleLowerCase();
    const passwordEncrypted = sha256(
      `${emailLowercase}${values.password}*0*eDimMlW$dfzqO#o7%hnU@Uv&vBARs`
    );

    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.email,
      password: passwordEncrypted,
      acceptTOS: userContext.userData.edu.user.acceptTOS
    };

    const response = await editUserInfo(requestConfig, data, userId);
    if (response.status) {
      userSignIn(emailLowercase, passwordEncrypted).then((data) => {
        if (data.status) {
          userContext.login(data.data);
          userContext.saveSubscriptions(userContext.subscriptions, userContext.userData.edu.user);
        } else {
          userContext.logout();
          navigate("/user", {state: {errorLogginWhenUpdateAccount: true}});
        }
        userContext.setFormProgress(100);
        props.onSubmit(data);
      });

    } else {
      userContext.setFormProgress(100);
      setButtonDisabled(false);
      setErrorUpdate(true);
    }
  };

  return (
    <div className="edit-account-wrapper">
      <Formik
        initialValues={{
          firstName: initialData.firstName,
          lastName: initialData.lastName,
          email: initialData.email,
          password: "",
          confirmPassword: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors }) => (
          <Form>
            {Object.keys(errors).length > 0 ? (
              <div className="errors">
                <div className="col-1 p-0 error-logo" />
                <ul className="p-0 errors-list">
                  <ErrorMessage
                    name="firstName"
                    component={() => (
                      <TextError wrapper="li" text={errors.firstName} />
                    )}
                  />
                  <ErrorMessage
                    name="lastName"
                    component={() => (
                      <TextError wrapper="li" text={errors.lastName} />
                    )}
                  />
                  <ErrorMessage
                    name="email"
                    component={() => (
                      <TextError wrapper="li" text={errors.email} />
                    )}
                  />
                  <ErrorMessage
                    name="password"
                    component={() => (
                      <TextError wrapper="li" text={errors.password} />
                    )}
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component={() => (
                      <TextError wrapper="li" text={errors.confirmPassword} />
                    )}
                  />
                </ul>
              </div>
            ) : null}

            <div className="edit-account-form">
              {errorUpdate && (
                <TextError
                  wrapper="div"
                  text="There was an error, We were not able to update your account, please try again"
                />
              )}
              <div className="form-item name">
                <label htmlFor="firstName">
                  First Name
                  <span className="form-required"> *</span>
                </label>
                <Field
                  type="text"
                  name="firstName"
                  className="form-input name"
                ></Field>
              </div>

              <div className="form-item name">
                <label htmlFor="lastName">
                  Last Name
                  <span className="form-required"> *</span>
                </label>
                <Field
                  type="text"
                  name="lastName"
                  className="form-input name"
                ></Field>
              </div>

              <div className="form-item">
                <label htmlFor="email">
                  Email
                  <span className="form-required"> *</span>
                </label>
                <Field
                  type="text"
                  name="email"
                  className="form-input email"
                ></Field>
              </div>

              <div className="form-item">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  name="password"
                  className="form-input password"
                ></Field>
              </div>

              <div className="form-item">
                <label htmlFor="re-enter-password">Re-enter Password</label>
                <Field
                  type="password"
                  name="confirmPassword"
                  className="form-input re-enter-password"
                ></Field>
                {props.rol === "admin" && (
                  <p className="description">
                    Password must be 8 or more characters and contain one
                    number, a capital, or a special character.
                  </p>
                )}
              </div>

              <div className="buttons-wrapper">
                <button
                  disabled={buttonDisabled}
                  type="button"
                  className="btn-modal btn-valencia"
                  onClick={() => props.onClick()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-modal btn-di-serria btn-spinner-indicator"
                  disabled={buttonDisabled}
                >
                  {saveButtonText}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditMyAccount;
