import { useState } from "react"
import Dashboard from "../../../../../pages/dashboard/Dashboard"
import { formSteps } from "../../../../../context/Forms/purchaseContext"
import {DEFAULT_DISTRICT_ADMIN_SKY_THEME} from "../../../../../utils/constants"
import PopUpModal from "../../../Teacher/Modals/PopUpModal"
import GiftForm from "../../../../Forms/gift/giftForm"

const TeacherGift = () => {

  return (
    <Dashboard rol="teacher">
      <div className="purchase-admin purchase-teacher-gift">
        <PopUpModal
          title={DEFAULT_DISTRICT_ADMIN_SKY_THEME.purchase.title}
          body={(
            <>
              <span>
                Give educators & their students the gift of reading &
                learning with a 12-month subscription <br/> to
                Skybrary for school!
              </span>
              <br/>
              <br/>
              <strong>Our Plans:</strong>
              <br/>
              <span>
            CLASSROOM PLAN ($179): Provides up to 35 seats or
            individual logins per class. <br/> SCHOOL PLAN ($1450): A
            bundle of 10 Classroom Plans for a discounted price.
            Each provides up to 35 <br/> seats per class, for a total of
            350 individual logins. *Savings of $340
            </span>
              <br/>
              <br/>
              <strong>All plans include:</strong>
              <br/>
              <span>
            Unlimited access to hundreds of
            books and video field trips <br/> Teacher portal with 40
            themed units & easy-to-use teacher management tools <br/>
            Individual logins to access the service at school and
            at home <br/> Reading reports to track student reading &
            viewing time
            </span>
              <br/>
              <br/>
              <br/>
              <span>
            Need more? For larger volume or district purchases,
            contact Skybrary@RIF.org.
            </span>
              <br/>
              <br/>
              <span>
            To get started, we just need a little bit of info
            about you and the gift recipient.
            </span>
            </>
          )}
          modalClass={'district-report-modal'}
        />
        <div className="admin-purchase-container">
          <GiftForm formSteps={formSteps.gift}/>
        </div>
      </div>
    </Dashboard>
  );
};

export default TeacherGift
