import { useContext, useEffect, useState } from "react";
import DashboardContext from "../../../../../context/dashboardInfo";
import { isCleverTheme } from "../../../../../utils/helpers";
import { DEFAULT_TEACHER_SKY_THEME } from "../../../../../utils/constants";
import UserContext from "../../../../../context/user";
import CleverContext from "../../../../../context/clever";

function SmallWidget() {
  const dashboard = useContext(DashboardContext);
  const [smallWidget, setSmallWidget] = useState(<div/>);

  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  const createMarkup = () => {
    return {__html: dashboard.information.teachers.right_small_widget};
  };

  useEffect(() => {
    setSmallWidget(<div dangerouslySetInnerHTML={createMarkup()}/>);
  }, [dashboard.information]);

  return (
    <div className="edu-small-widget white-container">
      {
        !isCleverTheme(userContext, cleverContext) ? (
          <>
            {smallWidget}
          </>
        ) : (
          <div className="spotlight-wrapper">
            <label className="literacy-central-title">LITERACY CENTRAL</label>
            <span> {DEFAULT_TEACHER_SKY_THEME.aboutLiteracyCentral} </span>
          </div>
        )
      }
    </div>
  )
}

export default SmallWidget;
