import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  getDistrictReportsForClever,
  getSummaryOfCleverDistrict,
} from "@api/rifService";
import CollapseComponent from "@components/Dashboard/Common/Collapse";
import PaginatedTable from "@components/Dashboard/Common/PaginatedTable";
import Table from "@components/Dashboard/Common/Table";
import Steeper from "@components/steeper/Steeper";
import CleverContext from "@context/clever";
import UserContext from "@context/user";
import Dashboard from "@pages/dashboard/Dashboard";
import {
  DEFAULT_TEACHER_SKY_THEME,
  PER_PAGE_REPORTS_FOR_CLEVER,
  SKYBRARY_CLEVER_TOKEN,
} from "@utils/constants";
import { isCleverTheme } from "@utils/helpers";
import PopUpModal from "../../Modals/PopUpModal";
import Cookies from "universal-cookie";

type TableRow = Record<string, any>;

const cookies = new Cookies();

function Reports() {
  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  let cleverToken = cookies.get(SKYBRARY_CLEVER_TOKEN);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
      ...(cleverToken && { cleveraccesstoken: cleverToken }),
    },
  };
  const collapseTextField = ["teachers", "reports"];
  const [dataTable, setDataTable] = useState<TableRow[]>([]);

  const cleverTheme = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );

  const teacherUserId = userContext.userData.edu.user.rrkEduUserId;

  let userEmail = userContext.userData.emailAddress;
  let mappedTableData: any[] = [];
  const [actualPage, setActualPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalBooksRead, setTotalBooksRead] = useState(0);
  const [totalTimeReading, setTotalTimeReading] = useState(0);
  const [totalVideosWathched, setTotalVideosWathched] = useState(0);

  const handlePage = async (actualPage: number) => {
    setActualPage(actualPage);
    userContext.setFormProgress(50);
    const reportResponse = await getDistrictReportsForClever(
      requestConfig,
      teacherUserId,
      PER_PAGE_REPORTS_FOR_CLEVER,
      actualPage,
    );
    if (!reportResponse.error) {
      userContext.saveSchoolReports(reportResponse);
    }

    setLastPage(reportResponse.pagination.lastPage);
  };

  useEffect(() => {
    if (cleverTheme) {
      const fetchInitialData = async () => {
        const reportResponse = await getDistrictReportsForClever(
          requestConfig,
          teacherUserId,
          PER_PAGE_REPORTS_FOR_CLEVER,
          actualPage,
        );
        if (!reportResponse.error) {
          userContext.saveSchoolReports(reportResponse);
          setLastPage(reportResponse.pagination.lastPage);
        }
      };

      fetchInitialData();
    }
  }, []);

  const mapTableDataForClever = (allReports: any) => {
    if (totalBooksRead === 0) {
      getSummaryOfCleverDistrict(requestConfig, null).then((summaryReport) => {
        let timeRead = summaryReport.data.booksHistory.totalTimeReading;
        setTotalBooksRead(
          summaryReport.data.booksHistory.amountOfUniqueBooksRead,
        );
        setTotalTimeReading(timeRead);
        setTotalVideosWathched(
          summaryReport.data.videosHistory.amountOfUniqueVideosWatched,
        );
      });
    }

    mappedTableData = allReports.map((report: any) => {
      let viewReportDetailClasses = "btn-table-moderate-green";
      if (!cleverTheme) {
        viewReportDetailClasses =
          report.booksRead > 0
            ? "btn-table-brandy-punch"
            : "btn-table-di-serria";
      }
      let colWrapperClass = "report-cell";
      let reportStar = <></>;
      if (report.booksRead > 0) {
        colWrapperClass += " has-read";
        reportStar = <div className="report-star" />;
        if (cleverTheme) {
          reportStar = (
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0L15.3064 8.2918H24.3882L17.0409 13.4164L19.8473 21.7082L12.5 16.5836L5.15268 21.7082L7.95911 13.4164L0.611794 8.2918H9.69357L12.5 0Z"
                fill="#800484"
              />
            </svg>
          );
        }
      }

      return {
        col1: (
          <div className={colWrapperClass}>
            <span>{report.classroomName}</span>
            {reportStar}
          </div>
        ),
        col2: (
          <div className={colWrapperClass}>
            <span>{report.schoolName}</span>
          </div>
        ),
        col3: (
          <div className={colWrapperClass}>
            <span>{report.grade}</span>
          </div>
        ),
        col4: (
          <div className={colWrapperClass}>
            <span>{report.teacherName}</span>
          </div>
        ),
        col5: (
          <div className={colWrapperClass}>
            <span>{report.booksRead}</span>
          </div>
        ),
        col6: (
          <div className={colWrapperClass}>
            <span>{Math.ceil(report.totalReadingTime / 60)}</span>
          </div>
        ),
        col7: (
          <div className={colWrapperClass}>
            <span>{report.videosWatched}</span>
          </div>
        ),
        col8: (
          <Link
            to={`/teachers/reports/classroom/${report.subId}`}
            className={`btn ${viewReportDetailClasses} reports-view-button`}
          >
            View
          </Link>
        ),
      };
    });

    mappedTableData.push({
      col4: "Totals",
      col5: totalBooksRead,
      col6: Math.ceil(totalTimeReading / 60),
      col7: totalVideosWathched,
      col8: "",
    });

    setDataTable(mappedTableData);
  };

  const mapTableData = (allReports) => {
    let tempTotalBooksRead: number = 0;
    let tempTotalTimeReading: number = 0;
    let tempTotalVideosWathched: number = 0;

    mappedTableData = allReports.map((report) => {
      tempTotalBooksRead = tempTotalBooksRead + report.booksRead;
      tempTotalTimeReading = tempTotalTimeReading + report.totalReadingTime;
      tempTotalVideosWathched = tempTotalVideosWathched + report.videosWatched;

      return {
        col1: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.classroomName}
            {report.booksRead > 0 &&
              (cleverTheme ? (
                <svg
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 0L15.3064 8.2918H24.3882L17.0409 13.4164L19.8473 21.7082L12.5 16.5836L5.15268 21.7082L7.95911 13.4164L0.611794 8.2918H9.69357L12.5 0Z"
                    fill="#800484"
                  />
                </svg>
              ) : (
                <div className="report-star" />
              ))}
          </div>
        ),
        col2: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.booksRead}
          </div>
        ),
        col3: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.totalReadingTime}
          </div>
        ),
        col4: (
          <div className={`${report.booksRead > 0 ? "has-read" : ""}`}>
            {report.videosWatched}
          </div>
        ),
        col5: (
          <Link
            to={`/teachers/reports/classroom/${report.subId}`}
            className={`btn ${
              cleverTheme
                ? "btn-table-moderate-green"
                : report.booksRead > 0
                  ? "btn-table-brandy-punch"
                  : "btn-table-di-serria"
            }`}
          >
            View
          </Link>
        ),
      };
    });

    mappedTableData.push({
      col1: "Totals",
      col2: tempTotalBooksRead,
      col3: tempTotalTimeReading,
      col4: tempTotalVideosWathched,
      col5: "",
    });

    setDataTable(mappedTableData);
  };

  useEffect(() => {
    document.title = "Reports | Reading Is Fundamental";

    if (
      cleverContext.isCleverUser() &&
      userContext.schoolReports &&
      userContext.subscriptions
    ) {
      mapTableDataForClever(userContext.schoolReports);
    } else if (userContext.schoolReports) {
      const teachersReports = userContext.schoolReports;
      let allSubscriptions: any;
      if (!userContext.subscriptions.error) {
        allSubscriptions = userContext.subscriptions;
      }

      let subscription;
      if (allSubscriptions) {
        if (userContext.userData.edu.user.type === "admin") {
          subscription = allSubscriptions.filter(
            (subscription: any) =>
              subscription.teacherId ===
              userContext.adminTeacher[0].edu.eduUserId,
          );
        } else {
          subscription = allSubscriptions.filter(
            (subscription: any) =>
              subscription.teacherId ===
              userContext.userData.edu.user.rrkEduUserId,
          );
        }

        let finalReports: any[] = [];
        for (let x = 0; x < teachersReports.length; x++) {
          for (let y = 0; y < subscription.length; y++) {
            if (
              teachersReports[x].subId === subscription[y].rrkSubscriptionEduId
            ) {
              finalReports = [...finalReports, teachersReports[x]];
            }
          }
        }
        mapTableData(finalReports);
      }
    }
  }, [userContext.schoolReports]);

  const items = [
    {
      step: 1,
      text: "Teacher Dashboard",
      link: "/teachers/dashboard",
    },
    {
      step: 2,
      text: "Reports",
      link: "/teachers/reports",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Class Name",
        accessor: "col1",
        width: 363,
      },
      {
        Header: "Books Read",
        accessor: "col2",
        width: 124,
      },
      {
        Header: "Time Reading (mins.)",
        accessor: "col3",
        width: 106,
      },
      {
        Header: "Videos Watched",
        accessor: "col4",
        width: 124,
      },
      {
        Header: "",
        accessor: "col5",
        width: 143,
      },
    ],
    [],
  );

  const cleverColumns = React.useMemo(
    () => [
      {
        Header: "Class Name",
        accessor: "col1",
        width: 356,
      },
      {
        Header: "School",
        accessor: "col2",
        width: 121.69,
      },
      {
        Header: "Grade",
        accessor: "col3",
        width: 100,
      },
      {
        Header: "Teacher",
        accessor: "col4",
        width: 281,
      },
      {
        Header: "Books Read",
        accessor: "col5",
        width: 141,
      },
      {
        Header: "Time Reading (minutes)",
        accessor: "col6",
        width: 356,
      },
      {
        Header: "Videos Watched",
        accessor: "col7",
        width: 95,
      },
      {
        Header: "",
        accessor: "col8",
        width: 95,
      },
    ],
    [],
  );

  const data = React.useMemo(() => dataTable, [dataTable]);

  return (
    <Dashboard rol="teacher">
      <div className="reports-wrapper">
        {!cleverTheme ? (
          <>
            <Steeper steps={items} activeStep={2} steeperType="dashboard" />
            <CollapseComponent
              title="Reports"
              dashboardField={collapseTextField}
              bodyImg=""
            />
          </>
        ) : (
          <>
            <PopUpModal
              title={DEFAULT_TEACHER_SKY_THEME.reportsDashboard.title}
              body={DEFAULT_TEACHER_SKY_THEME.reportsDashboard.body}
              modalClass={"report-modal"}
            />
            <h1 className="title">Reports</h1>
          </>
        )}
        <div className="teachers-reports-table">
          {!cleverTheme && (
            <div className="main-header all-classes-user-email">
              All classes: {userEmail}
            </div>
          )}
          {cleverTheme ? (
            <PaginatedTable
              data={data}
              columns={cleverColumns}
              modalClass={"report-table"}
              sortArrows={false}
              page={actualPage}
              handleChangePage={handlePage}
              lastPage={lastPage}
              btnStyle={"teachers-reports-table-btn"}
            />
          ) : (
            <Table data={data} columns={columns} modalClass={"report-table"} />
          )}
        </div>
      </div>
    </Dashboard>
  );
}

export default Reports;
