import TeacherHeaderTabs from "../../Common/Header/Tabs";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import UserContext from "../../../../context/user";
import CleverContext from "../../../../context/clever";
import { isCleverTheme } from "../../../../utils/helpers";

function TeacherHeaderSections() {
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");
  const [active5, setActive5] = useState("");

  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);

  let path = window.location.pathname.split("/")[2];

  let pathUnauthorized = window.location.pathname;

  if (window.location.pathname === "/teachers/manage/account") {
    path = "";
  }

  const cleanActives = () => {
    setActive1("");
    setActive2("");
    setActive3("");
    setActive4("");
    setActive5("");
  };

  useEffect(() => {
    switch (path) {
      case "dashboard":
        cleanActives();
        setActive1("active");
        break;
      case "manage":
        cleanActives();
        setActive2("active");
        break;
      case "reports":
        cleanActives();
        setActive3("active");
        break;
      case "curriculum":
        cleanActives();
        setActive4("active");
        break;
      case "library":
        cleanActives();
        setActive5("active");
        break;
      default:
        cleanActives();
        break;
    }
  }, [path]);

  let tabs, justify;

  if (userContext.userData.type === 'edu-admin' &&
      (pathUnauthorized === "/unauthorized/admin" ||
      !userContext.adminHasActiveSubscription) ) {
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal ms-4">
        <TeacherHeaderTabs
          to={"/teachers/dashboard"}
          classes={`first ${active1}`}
          text={"My Dashboard"}
        />
      </ListGroup>
    );

    justify = "";
  } else if (!userContext.hasActiveSubscription) {
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal ms-4">
        <TeacherHeaderTabs
          to={"/teachers/dashboard"}
          classes={`first ${active1}`}
          text={"My Dashboard"}
        />
      </ListGroup>
    );
  } else {
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal">
        <TeacherHeaderTabs
          to={"/teachers/dashboard"}
          classes={`first ${active1}`}
          text={"My Dashboard"}
        />

        <TeacherHeaderTabs
          to={"/teachers/manage/rosters"}
          classes={active2}
          text={"Class Roster"}
        />

        <TeacherHeaderTabs
          to={"/teachers/reports"}
          classes={active3}
          text={"Reports"}
        />

        <TeacherHeaderTabs
          to={"/teachers/curriculum"}
          classes={active4}
          text={"Curriculum"}
        />

        <TeacherHeaderTabs
          to={"/teachers/library"}
          classes={active5}
          text={"Library"}
        />
        {!userContext.isLoading && (
          <ListGroup.Item as="li" className="last">
            <a
              href={process.env.REACT_APP_WEBAPP_DOMAIN}
              className="nav-link open-skybrary"
              target={"blank"}
              rel="noreferrer"
            >
              {" "}
              Launch Skybrary{" "}
            </a>
          </ListGroup.Item>
        )}
      </ListGroup>
    );
    justify = "justify-content-end";
  }

  return <div className={`navbar ${isCleverTheme(userContext, cleverContext) ? 'navbar-sky' : 'navbar-green'} ${justify}`}>{tabs}</div>;
}

export default TeacherHeaderSections;
