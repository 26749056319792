import Collapse from "../../../Common/Collapse";
import Table from "../../../Common/Table";
import Steeper from "../../../../steeper/Steeper";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useMemo } from "react";
import EditClassInfoModal from "../../Modals/EditClassInfo";
import AddStudentsModal from "../../Modals/AddStudents";
import ModalWindow from "../../../Common/ModalWindow";
import { useState } from "react";
import Dashboard from "../../../../../pages/dashboard/Dashboard";
import UserContext from "../../../../../context/user";
import UploadStudentsModal from "../../../Common/Modals/UploadCsvModal";
import TextSuccess from "../../../../TextSuccess";
import UploadingStudentsCsv from "../../../Common/Modals/UploadingCsvFile";
import rifLogo from "../../../../../images/rif-logo.png";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import PopUpModal from "../../Modals/PopUpModal";
import {
  DEFAULT_TEACHER_SKY_THEME,
  SKYBRARY_CLEVER_TOKEN,
} from "../../../../../utils/constants";
import PaginatedTable from "@components/Dashboard/Common/PaginatedTable";
import {
  getSchoolSubscriptions,
  getSchoolSubscriptionsForClever,
} from "@api/rifService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function ClassRoster() {
  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  const cleverTheme = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );

  let cleverToken = cookies.get(SKYBRARY_CLEVER_TOKEN);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
      ...(cleverToken && { cleveraccesstoken: cleverToken }),
    },
  };

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const handlePage = async (currentPage: number) => {
    setCurrentPage(currentPage);
    userContext.setFormProgress(50);
    const reportResponse = await getSchoolSubscriptionsForClever(
      requestConfig,
      10,
      currentPage,
    );
    if (!reportResponse.error) {
      allSubscriptions = reportResponse;
      mapTableData();
    }

    setIsTableLoading(false);
    setLastPage(reportResponse.pagination.lastPage);
  };

  const collapseTextField = [
    "teachers",
    cleverContext.isCleverUser() ? "manage_clever_rosters" : "manage_rosters",
  ];

  let mappedTableData: any[] = [];
  let allSubscriptions: any = userContext.subscriptions
    ? userContext.subscriptions
    : {};

  let teacherName: string = `${userContext.userData.edu.user.firstName} ${userContext.userData.edu.user.lastName}`;

  const mapTableData = () => {
    mappedTableData = allSubscriptions.map(
      (subscription: any, index: number) => {
        let date = new Date(allSubscriptions[index].expirationDate);
        let dateParts = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
        return {
          col1: subscription.className,
          col2: subscription.classroomCode
            ? subscription.classroomCode
            : "No Code",
          ...(cleverContext.isCleverUser()
            ? {}
            : {
                col3: (
                  <div className="edit-class-options">
                    {!subscription.classroomCode ? (
                      <div className="start-link">
                        <Link
                          to=""
                          className="btn btn-table-di-serria"
                          onClick={() =>
                            handleModal(
                              "edit-class-info-modal",
                              subscription.rrkSubscriptionEduId,
                              subscription.teacherId,
                              subscription.className,
                              subscription.classroomCode,
                            )
                          }
                        >
                          START
                        </Link>
                      </div>
                    ) : (
                      <div className="edit-link">
                        <Link
                          to=""
                          className="btn btn-table-di-serria"
                          onClick={() =>
                            handleModal(
                              "edit-class-info-modal",
                              subscription.rrkSubscriptionEduId,
                              subscription.teacherId,
                              subscription.className,
                              subscription.classroomCode,
                            )
                          }
                        >
                          EDIT
                        </Link>
                      </div>
                    )}
                  </div>
                ),
              }),
          col4: (
            <div className="links-container">
              {!subscription.hasStudents && !cleverContext.isCleverUser() ? (
                <div className="link-add-students">
                  <Link
                    to=""
                    className={`btn btn-table-${
                      !subscription.classroomCode ? "silver" : "di-serria"
                    }`}
                    onClick={
                      subscription.classroomCode
                        ? () =>
                            handleModal(
                              "add-students-modal",
                              subscription.rrkSubscriptionEduId,
                              null,
                              null,
                              null,
                            )
                        : () => {}
                    }
                  >
                    ADD STUDENTS
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={`/teachers/manage/rosters/${subscription.rrkSubscriptionEduId}`}
                    className={`btn ${cleverTheme ? `btn-table-moderate-green` : `btn-table-di-serria`}`}
                  >
                    {!cleverContext.isCleverUser()
                      ? "VIEW/EDIT STUDENTS"
                      : "VIEW STUDENTS"}
                  </Link>
                </div>
              )}
            </div>
          ),
          ...(cleverContext.isCleverUser()
            ? {}
            : {
                col4bis: (
                  <div className="links-container">
                    <div className="link-print-passwords">
                      <Link
                        to={`${
                          subscription.hasStudents
                            ? `/teachers/classroom/print/${subscription.rrkSubscriptionEduId}`
                            : "#"
                        }`}
                        className={`btn btn-table-${
                          !subscription.hasStudents ? "silver" : "di-serria"
                        } ${!subscription.hasStudents ? "no-students" : ""}`}
                        target={`${!subscription.hasStudents ? "_self" : "_blank"}`}
                      >
                        PRINT PASSWORDS
                      </Link>
                    </div>
                  </div>
                ),
              }),
          col5: (
            <span className="roster-date">
              {dateParts.month < 10 ? "0" : ""}
              {dateParts.month}/{dateParts.day}/{dateParts.year}
            </span>
          ),
        };
      },
    );

    setDataTable(mappedTableData);
  };

  useEffect(() => {
    document.title = "Class Roster | Reading Is Fundamental";
    handleRoster();
  }, []);

  const [dataTable, setDataTable] = useState(mappedTableData);

  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const schoolId = userContext.userData.edu.user.rrkSchoolId;

  const handleRoster = () => {
    let teacherId;
    if (userContext.userData.edu.user.type === "admin") {
      teacherId = Number(userContext.userData.edu.user.relatedAccountId);
    } else {
      teacherId = Number(userContext.userData.edu.user.rrkEduUserId);
    }
    userContext.setFormProgress(50);
    if (cleverContext.isCleverUser()) {
      getSchoolSubscriptionsForClever(requestConfig, 10, currentPage).then(
        (schoolSubscription) => {
          userContext.setFormProgress(100);
          allSubscriptions = schoolSubscription;
          setLastPage(schoolSubscription.pagination.lastPage);
          setIsLoading(false);
          mapTableData();
        },
      );
    } else {
      getSchoolSubscriptions(requestConfig, schoolId, null).then(
        (schoolSubscription) => {
          userContext.setFormProgress(100);
          setIsLoading(false);
        },
      );
      getSchoolSubscriptions(requestConfig, schoolId, teacherId).then(
        (schoolSubscription) => {
          userContext.setFormProgress(100);
          allSubscriptions = schoolSubscription;
          setIsLoading(false);
          mapTableData();
        },
      );
    }
    handleClose();
  };

  let [studentsUploaded, setStudentsUploaded] = useState(0);
  const [successUploadMessage, setSuccessUploadMessage] = useState(false);

  const handleUploadState = (uploaded: number) => {
    setSuccessUploadMessage(true);
    setStudentsUploaded(uploaded);
    handleRoster();
  };

  const handleUploadSuccess = (
    response: any,
    formData: any,
    subscriptionId: any,
  ) => {
    setModal({
      show: true,
      class: "uploading-students-modal",
      onClick: handleClose,
      header: (
        <div className="logo">
          <Link to="/">
            <img src={rifLogo} alt="" />
          </Link>
        </div>
      ),
      body: (
        <UploadingStudentsCsv
          onClick={handleClose}
          onSubmit={handleUploadState}
          schoolId={userContext.subscriptions[0].schoolId}
          batchId={response.data.batchId}
          formData={formData}
          subscriptionId={subscriptionId}
        />
      ),
    });
  };

  const handleUploadModal = (subscriptionId: any) => {
    setModal({
      show: true,
      class: "upload-students-modal",
      onClick: handleClose,
      header: <></>,
      body: (
        <UploadStudentsModal
          onClick={handleClose}
          onSubmit={handleUploadSuccess}
          schoolId={userContext.subscriptions[0].schoolId}
          subscriptionId={subscriptionId}
          csv="students"
        />
      ),
    });
  };

  const handleModal = (
    modal: string,
    subscriptionId: number,
    teacherId: number = 0,
    className: string,
    classCode: any = "",
  ) => {
    setSuccessUploadMessage(false);
    switch (modal) {
      case "edit-class-info-modal":
        setModal({
          show: true,
          class: "edit-class-info-modal",
          onClick: handleClose,
          header: <h1>Edit Class Info</h1>,
          body: (
            <EditClassInfoModal
              onClick={handleRoster}
              subscriptionId={subscriptionId}
              teacherId={teacherId}
              className={className}
              classCode={classCode}
            />
          ),
        });
        break;
      case "add-students-modal":
        setModal({
          show: true,
          class: "add-students-modal",
          onClick: handleClose,
          header: <h1>How do you want to enter students?</h1>,
          body: (
            <AddStudentsModal
              subscriptionId={subscriptionId}
              onUpload={handleUploadModal}
            />
          ),
        });
        break;
    }
  };

  const items = [
    {
      step: 1,
      text: "Teacher Dashboard",
      link: "/teachers/dashboard",
    },
    {
      step: 2,
      text: "Class Roster",
      link: "/teachers/manage/rosters",
    },
  ];

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Class Name",
        columns: [
          {
            accessor: "col1",
            width: cleverContext.isCleverUser() ? 302 : 180.42,
            customStyles: {
              textAlign: "left",
              paddingLeft: "0.9375rem"
            }
          },
        ],
      },
      ...(cleverContext.isCleverUser()
        ? []
        : [
            {
              Header: "Classroom Code",
              columns: [
                {
                  accessor: "col2",
                  width: 101.79,
                  customStyles: {
                    textAlign: "left",
                    paddingLeft: "0.9375rem"
                  }
                },
              ],
            },
          ]),
      ...(cleverContext.isCleverUser()
        ? []
        : [
            {
              Header: "",
              accessor: "col3",
              columns: [
                {
                  accessor: "col3",
                  width: 102.91,
                },
              ],
            },
          ]),
      {
        Header: cleverContext.isCleverUser()
          ? "Student Information"
          : "Student Related",
        accessor: "col4",
        columns: [
          {
            accessor: "col4",
            width: cleverContext.isCleverUser() ? 302 : 182.49,
          },
          ...(cleverContext.isCleverUser()
            ? []
            : [
                {
                  accessor: "col4bis",
                  width: 172.63,
                },
              ]),
        ],
      },
      {
        Header: "Expiration Date",
        columns: [
          {
            accessor: "col5",
            width: cleverContext.isCleverUser() ? 302 : 121.78,
          },
        ],
      },
    ];
  }, []);

  const data = React.useMemo(() => dataTable, [dataTable]);

  return (
    <Dashboard rol="teacher">
      <div className="class-roster-wrapper">
        {!cleverTheme ? (
          <>
            <Steeper steps={items} activeStep={2} steeperType="dashboard" />
            <Collapse
              title="Class Roster"
              dashboardField={collapseTextField}
              bodyImg=""
            />
          </>
        ) : (
          <>
            <PopUpModal
              title={DEFAULT_TEACHER_SKY_THEME.rosterDashboard.title}
              body={DEFAULT_TEACHER_SKY_THEME.rosterDashboard.body}
              modalClass={"roster-modal"}
            />
            <h1 className="title">Class Roster</h1>
          </>
        )}
        {successUploadMessage && (
          <div className="text-success-dashboards py-2 px-3">
            <TextSuccess text={`${studentsUploaded} Students Imported`} />
          </div>
        )}
        <div className="class-roster-table ">
          <div className="main-header user-classroster">
            All Class Roster : {teacherName}
          </div>
          {cleverTheme ? (
            <PaginatedTable
              data={data}
              columns={columns}
              modalClass=""
              sortArrows={false}
              page={currentPage}
              handleChangePage={handlePage}
              lastPage={lastPage}
              btnStyle="teachers-reports-table-btn"
              isTableLoading={isTableLoading}
              setIsTableLoading={setIsTableLoading}
            />
          ) : (
            <Table data={data} isLoading={isLoading} columns={columns} />
          )}
        </div>

        <ModalWindow
          show={modal.show}
          class={modal.class}
          onClick={modal.onClick}
          header={modal.header}
          body={modal.body}
        />
      </div>
    </Dashboard>
  );
}

export default ClassRoster;
