import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserProvider from "@context/user";
import GiftContext from "@context/Forms/giftContext";
import CleverContext from "@context/clever";

function Options(props: { rol: string }) {
  const giftContext = useContext(GiftContext);
  const navigate = useNavigate();
  const cleverContext = useContext(CleverContext);
  const userContext = useContext(UserProvider);

  let links = {
    account: "/teachers/manage/account",
    help: "/teachers/pages/help",
    redeem: "",
  };

  if (props.rol === "admin") {
    links.account = "/adm/manage";
    links.help = "/adm/pages/help";
    links.redeem = "/adm/gift/redeem/manual";
  } else if (props.rol === "parent") {
    links.account = "/parent/manage";
    links.help = "/parent/pages/help";
  }

  const logout = () => {
    giftContext.setLastUrl("");
    userContext.logout();
    navigate("/");
  };

  let helpPage = false;

  const isAdmin = props.rol === "admin";

  const helpPagePaths = [
    "/teachers/pages/help",
    "/adm/pages/help",
    "/parent/pages/help",
  ];

  if (helpPagePaths.indexOf(window.location.pathname) !== -1) {
    helpPage = true;
  }

  return (
    <div
      className={`navbar user-options justify-content-end ${helpPage ? "help-page" : ""} ${userContext.isTrial ? "is-trial" : "non-trial"}`}
    >
      <ul className="list-group list-group-horizontal">
        <li className="list-group-item first">
          <button
            className="logout-button"
            type="button"
            onClick={() => logout()}
          >
            Logout
          </button>
        </li>
        {!cleverContext.isCleverUser() && (
          <li className="list-group-item">
            <Link to={links.account}>My Account</Link>
          </li>
        )}
        {cleverContext.isCleverUser() && isAdmin && (
          <li className="list-group-item">
            <Link to={links.redeem}>Redeem Code</Link>
          </li>
        )}
        <li className="list-group-item">
          <Link to={links.help}>Help</Link>
        </li>
        <li className="list-group-item">
          <a
            href="https://skybrary.org/school/privacy-policy?from-edu=1"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li className="list-group-item last">
          <a
            href="https://skybrary.org/school/terms-of-use?from-edu=1"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Options;