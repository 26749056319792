import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import ModalWindow from "../../../Common/ModalWindow";
import CurriculumResourcesModal from "../../Modals/CurriculumResources";
import CleverContext from "../../../../../context/clever";
import { isCleverTheme } from "../../../../../utils/helpers";
import UserContext from "../../../../../context/user";

function TeacherGuides(props: {
  title: string;
  imageUrl: string;
  description: string;
  file: string;
  fileDownload: string;
}) {
  const [modal, setModal] = useState({
    show: false,
    class: "",
    onClick: () => {},
    header: <div />,
    body: <div />,
  });

  const handleClose = () => {
    setModal({
      show: false,
      class: "",
      onClick: () => {},
      header: <div />,
      body: <div />,
    });
  };

  const cleverContext = useContext(CleverContext);
  const userContext: any = useContext(UserContext);

  const handleModal = () => {
    setModal({
      show: true,
      class: isCleverTheme(userContext, cleverContext) ? ("sky-curriculum-resource-modal") : ("curriculum-resource-modal"),
      onClick: handleClose,
      header: isCleverTheme(userContext, cleverContext) ? (<></>) : (<h1>{props.title}</h1>),
      body: (
        <CurriculumResourcesModal
          imageUrl={props.imageUrl}
          title={isCleverTheme(userContext, cleverContext) ? (props.title) : ("")}
          description={props.description}
          file={props.file ? props.file : ""}
          fileDownload={props.fileDownload ? props.fileDownload : ""}
        />
      ),
    });
  };

  return (
    <div className="curriculums">
      <Link to="" className="curriculum" onClick={() => handleModal()}>
        <div className="image">
          <img src={props.imageUrl} alt="" />
        </div>
        <label>{props.title}</label>
      </Link>

      <ModalWindow
        show={modal.show}
        class={modal.class}
        onClick={modal.onClick}
        header={modal.header}
        body={modal.body}
      />
    </div>
  );
}

export default TeacherGuides;
