import { useBlockLayout, useResizeColumns, useTable } from "react-table";

interface TableNoResultProps {
  data: any;
  columns: any;
  noResultText?: string;
  modalClass?: string;
}

const TableNoResult = ({
  data,
  columns,
  noResultText = "No subscriptions found.",
  modalClass,
}: TableNoResultProps) => {
  const { getTableProps, headerGroups } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useResizeColumns,
  );

  return (
    <>
      <table {...getTableProps()} className={`edu-table ${modalClass}`}>
        <thead className="table-header">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                width: "100%",
              }}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body">
          <tr>
            <td colSpan={columns.length}>{noResultText}</td>
          </tr>
          <tr>
            <td colSpan={columns.length}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TableNoResult;
