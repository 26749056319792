import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRosterStudents } from "../../../../../api/rifService";
import UserContext from "../../../../../context/user";
import { PrintHandlerComponent } from "./PrintHandlerComponent";

const PrintPasswords = () => {
  const userContext: any = useContext(UserContext);
  const params = useParams();
  let schoolId = userContext.userData.edu.user.rrkSchoolId;
  const [allStudents, setAllStudents] = useState([]);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  useEffect(() => {
    if (schoolId) {
      getRosterStudents(
        requestConfig,
        schoolId,
        params.classId
      ).then((response) => {
        if (response) {
          setAllStudents(response);
        }
      });
    }
  }, [schoolId])

  return (
    <div className="print-passwords">
      <div className="row">
        {userContext.isLoading && <div className="loading-info div-spinner-indicator"></div>}
        {!userContext.isLoading && <PrintHandlerComponent students={allStudents}/>}
      </div>
    </div>
  );
};

export default PrintPasswords;
