import { useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import UserContext from "../../../../../context/user";
import StudentPasswords from "./StudentPasswords";

export const PrintHandlerComponent = (props: { students: any}) => {
  const userContext = useContext(UserContext);
  const passwordRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => passwordRef.current,
  });

  if (!userContext.isLoading) {
    return (
      <StudentPasswords
        refToAsign={passwordRef}
        students={props.students}
        onLoad={handlePrint}
      />
    );
  }
};
