import { useContext, useState } from "react";
import { Formik, Form, Field, FormikValues } from "formik";
import {
  addStudentToRoster,
  editStudentsInfo,
} from "../../../../api/rifService";
import UserContext from "../../../../context/user";
import PasswordForm from "../../../Forms/PasswordForm";
import TextError from "../../../TextError";

function AddStudentsManually(props: {
  onClick: any;
  teacherId: any;
  subscriptionId: any;
  studentInfo: any;
  type: string;
}) {
  const userContext = useContext(UserContext);
  const [password, setPassword] = useState({ password: "", type: "" });
  const [usernameValue, setUsernameValue] = useState(
    props.studentInfo.userName ? props.studentInfo.userName : ""
  );
  const [firstName, setFirstName] = useState(
    props.studentInfo.firstName ? props.studentInfo.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.studentInfo.lastName ? props.studentInfo.lastName : ""
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userContext.token}`,
    },
  };

  const schoolId = userContext.userData.edu.user.rrkSchoolId;

  const handlerChangeFirstName = (event: any) => {
    if (
      firstName.length < 15 ||
      event.nativeEvent.inputType === "deleteContentBackward" ||
      event.nativeEvent.inputType === "deleteContentForward"
    ) {
      setFirstName(event.target.value);
      if (props.type === "add") {
        if (lastName === "") {
          setUsernameValue(`${event.target.value}`);
        } else {
          if (usernameValue.length < 20) {
            setUsernameValue(`${event.target.value}.${lastName}`);
          }
        }
      }
    }
  };

  const handlerChangeLastName = (event: any) => {
    if (lastName.length < 15 ||
      event.nativeEvent.inputType === "deleteContentBackward" ||
      event.nativeEvent.inputType === "deleteContentForward") {
      setLastName(event.target.value);
      if (props.type === "add") {
        if (event.target.value === "") {
          setUsernameValue(`${firstName}`);
        } else {
          if (usernameValue.length < 20) {
            setUsernameValue(`${firstName}.${event.target.value}`);
          }
        }
      }
    }
  };

  const handlerChangeUsername = (event: any) => {
    if (usernameValue.length < 20 ||
      event.nativeEvent.inputType === "deleteContentBackward" ||
      event.nativeEvent.inputType === "deleteContentForward") {
      setUsernameValue(event.target.value);
    }
  };

  const getPassword = (data: { password: string; type: string }) => {
    setPassword({ password: data.password, type: data.type });
  };

  const [addStudentError, setAddStudentError] = useState(false);
  const [editStudentError, setEditStudentError] = useState(false);
  const [clientError, setClientError] = useState("");
  const [emptyPasswordError, setEmptyPasswordError] = useState(false);
  const [emptyFirstNameError, setEmptyFirstNameError] = useState(false);
  const [emptyUserNameError, setEmptyUserNameError] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    setEmptyPasswordError(false);
    setEmptyFirstNameError(false);
    setEmptyUserNameError(false);

    if (password.password !== "" && firstName !== "" && usernameValue !== "") {
      if (password.type === "icon") {
        if (password.password[1]) {
          password.password = password.password[0] + password.password[1];
        } else {
          password.password = password.password[0];
        }
      }
      userContext.setFormProgress(50);
      if (props.type === "add") {
        setAddStudentError(false);
        const response = await addStudentToRoster(requestConfig, {
          eduSubId: props.subscriptionId,
          schoolId: userContext.userData.edu.user.rrkSchoolId,
          teacherId: props.teacherId,
          firstName: firstName,
          lastName: lastName,
          userName: usernameValue,
          cleanPass: password.password,
          parentEmail: values.parentEmail,
          passType: password.type,
        });
        if (response.status) {
          userContext.setFormProgress(100);
          props.onClick(true, "add");
        } else if (response.data.errorCode !== 400) {
          userContext.setFormProgress(100);
          setAddStudentError(true);
        } else if (response.data.errorCode === 400) {
          userContext.setFormProgress(100);
          setClientError(response.data.errorMessage);
          setAddStudentError(true);
        }
      } else if (props.type === "edit") {
        const response = await editStudentsInfo(
          requestConfig,
          {
            eduSubId: props.subscriptionId,
            schoolId: userContext.userData.edu.user.rrkSchoolId,
            teacherId: props.teacherId,
            firstName: firstName,
            lastName: lastName,
            userName: usernameValue,
            cleanPass: password.password,
            parentEmail: values.parentEmail,
            passType: password.type,
          },
          schoolId,
          props.subscriptionId,
          props.studentInfo.rosterId
        );
        if (response.status) {
          userContext.setFormProgress(100);
          props.onClick(true, "edit");
        } else if (response.data.errorCode !== 400) {
          userContext.setFormProgress(100);
          setAddStudentError(true);
        } else if (response.data.errorCode === 400) {
          userContext.setFormProgress(100);
          setClientError(response.data.errorMessage);
          setAddStudentError(true);
        }
      }
    } else {
      if (password.password === "") {
        setEmptyPasswordError(true);
      }
      if (firstName === "") {
        setEmptyFirstNameError(true);
      }
      if (usernameValue === "") {
        setEmptyUserNameError(true);
      }
    }
  };

  return (
    <div className="modal-body-wrapper">
      {addStudentError && clientError === "" && (
        <TextError
          wrapper="div"
          text={
            "There was an error, We were not able to Add the student, please try again"
          }
        />
      )}
      {addStudentError && clientError !== "" && (
        <TextError wrapper="div" text={clientError} />
      )}
      {editStudentError && (
        <TextError
          wrapper="div"
          text={
            "There was an error, We were not able to Edit the student's information, please try again"
          }
        />
      )}
      <div className="errors">
        {emptyPasswordError || emptyUserNameError || emptyFirstNameError ? (
          <div className="error-logo"></div>
        ) : (
          <></>
        )}
        {emptyPasswordError && (
          <TextError wrapper="div" text={"Password field is required."} />
        )}
        {emptyFirstNameError && (
          <TextError wrapper="div" text={"First Name field is required."} />
        )}
        {emptyUserNameError && (
          <TextError wrapper="div" text={"User Name field is required."} />
        )}
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          userName: "",
          parentEmail: props.studentInfo.parentEmail
            ? props.studentInfo.parentEmail
            : "",
        }}
        onSubmit={handleSubmit}
      >
        <Form className="modal-form add-student-form">
          <div className="title-wrapper">
            <legend className="subtitle-wrapper">
              <span>Info</span>
              <div className="bar"></div>
            </legend>
          </div>

          <div className="form-item">
            <label>
              Fist Name
              <span className="form-required" title="This field is required">
                *
              </span>
            </label>
            <Field
              type="text"
              name="firstName"
              placeholder="First Name"
              className="form-input student-first-name"
              onChange={handlerChangeFirstName}
              value={firstName}
            ></Field>
          </div>
          <div className="form-item">
            <label>Last Name</label>
            <Field
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="form-input student-last-name"
              value={lastName}
              onChange={handlerChangeLastName}
            ></Field>
          </div>
          <div className="form-item">
            <label>
              User Name
              <span className="form-required" title="This field is required">
                *
              </span>
            </label>
            <Field
              type="text"
              name="userName"
              placeholder="User Name"
              className="form-input student-user-name"
              value={usernameValue}
              onChange={handlerChangeUsername}
            ></Field>
          </div>
          <div className="form-item">
            <label>Parent Email</label>
            <Field
              type="email"
              name="parentEmail"
              placeholder="Parent Email"
              className="form-input parent-email"
            ></Field>
          </div>
          <div className="password-info">
            <div className="title-wrapper">
              <legend className="subtitle-wrapper">
                <span>Password</span>
                <div className="bar"></div>
              </legend>
            </div>

            <PasswordForm
              from="modal"
              onSubmit={handleSubmit}
              onPasswordChange={getPassword}
              password={{
                passwordType: props.studentInfo.passType,
                password: props.studentInfo.cleanPass,
              }}
            />
          </div>
          <div className="add-student-buttons-wrapper mt-3 text-center">
            <button
              className="btn-modal btn-mojo-modal"
              type="button"
              onClick={() => props.onClick(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-modal btn-brandy-punch btn-spinner-indicator"
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default AddStudentsManually;
