import TextSuccess from "@components/TextSuccess";
import { useCleverContext } from "@context/clever";
import { useUserContext } from "@context/user";
import Dashboard from "@pages/dashboard/Dashboard";
import { DEFAULT_TEACHER_SKY_THEME } from "@utils/constants";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PopUpModal from "../../Modals/PopUpModal";
import ExtraInfo from "./ExtraInfo";
import Info from "./Info";
import LargeWidget from "./LargeWidget";
import LargeWidgetFeaturedContent from "./LargeWidgetFeaturedContent";
import Links from "./Links";
import SmallWidget from "./SmallWidget";
import SmallWidgetSpotlight from "./SmallWidgetSpotlight";

function TeacherDashboard() {
  const location: any = useLocation();
  const userContext = useUserContext();
  const cleverContext = useCleverContext();

  const [passwordMessage, setPasswordMessage] = useState(false);
  const [successEmailTrial, setSuccessEmailTrial] = useState(false);
  const [migrationMessage, setMigrationMessage] = useState({
    status: false,
    message: "",
  });
  const isClever = cleverContext.isCleverUser();
  let userType = userContext.userData.edu.user.userFirstLogin
    ? "clever"
    : "clever_normal";

  window.history.replaceState({}, "");

  if (isClever) {
    if (userContext.students) {
      userType = userContext.userData.edu.user.userFirstLogin
        ? "clever"
        : "clever_normal";
    }
  } else if (userContext.userData.edu.user.isTrial) {
    userType = "trial";
  } else if (
    !userContext.userData.edu.user.isTrial &&
    userContext.hasMultipleStudents
  ) {
    userType = "normal";
  } else {
    userType = "multiple";
  }

  useEffect(() => {
    document.title = "My Dashboard | Reading Is Fundamental";

    const giftRedeemPage = cleverContext.giftForClever();

    if (giftRedeemPage) {
      setMigrationMessage({
        status: true,
        message: `You tried to redeem a clever gift, only admins are allowed to do it.`,
      });
    }

    if (location.state !== null) {
      if (location.state?.successEmail) {
        setSuccessEmailTrial(true);
      }
      if (location.state?.migration?.status) {
        if (location.state?.migration.accepts) {
          setMigrationMessage({
            status: true,
            message: `You have been successfully transferred to the ${location.state?.migration.adminInfo.schoolName}, administrated by ${location.state?.migration.adminInfo.adminName}.`,
          });
        } else {
          setMigrationMessage({
            status: true,
            message: "Your pending migration request has been cancelled.",
          });
        }
      }
      if (location.state?.migrationFull?.status) {
        setMigrationMessage({
          status: true,
          message: location.state.migrationFull.errorMessage,
        });
      }
      if (location.state?.setPassword) {
        userContext.saveIsLogging(false);
        setPasswordMessage(true);
        window.history.replaceState({}, "");
      }
    }
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (userContext.userData) {
      setFirstName(userContext.userData.edu.user.firstName);
      setLastName(userContext.userData.edu.user.lastName);
    }
  }, [userContext.userData]);

  return (
    <Dashboard rol="teacher">
      <div className="row home-dashboard">
        {successEmailTrial && (
          <div className="successEmail mb-5 p-0">
            <TextSuccess text="An email was sent to your administrator. He/she will need to click the link in the email and complete the purchase." />
          </div>
        )}
        {migrationMessage.status && (
          <div className="successEmail mb-5 p-0">
            <TextSuccess text={migrationMessage.message} />
          </div>
        )}
        {passwordMessage && (
          <div className="text-success-dashboards password-changed px-0 mb-5">
            <div className="text-send ps-5">Password Changed!</div>
          </div>
        )}
        <div className=" p-0 left">
          <h1>{`Welcome ${firstName} ${lastName}!`}</h1>
          <Info userType={userType} />
          {!isClever && (
            <>
              <ExtraInfo />
              <Links userType={userType} />
            </>
          )}
          {isClever && (
            <PopUpModal
              title={DEFAULT_TEACHER_SKY_THEME.teacherDashboard.title}
              body={DEFAULT_TEACHER_SKY_THEME.teacherDashboard.body}
              modalClass="teacher-dashboard-modal"
            />
          )}
        </div>
        <div className=" right">
          <div className="table-teacher">
            {!isClever && (
              <div className="row">
                <div className="col tutorials">
                  <div className="main-tutorial">
                    <a
                      href="https://cf.rrkidz.com/files/downloads/EDU_Tutorial_TeacherDashboard.pdf"
                      className="download"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Teacher Dashboard Tutorial
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col large-feature">
                <LargeWidgetFeaturedContent />
              </div>
            </div>
            <div className="row">
              <div className="col col-5">
                <SmallWidgetSpotlight />
              </div>
              <div className="col col-5">
                <SmallWidget />
              </div>
            </div>
            {!isClever && (
              <div className="row">
                <div className="col">
                  <LargeWidget />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default TeacherDashboard;
