import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../../context/user";
import { ListGroup } from "react-bootstrap";
import ParentHeaderTabs from "../../Common/Header/Tabs";

const ParentHeaderSections = () => {
  const userContext = useContext(UserContext);
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  let path = window.location.pathname.split("/").slice(-1)[0];
  let tabs;
  let justify = "";

  if (window.location.pathname === "/teachers/manage/account") {
    path = "";
  }
  
  const cleanActives = () => {
    setActive1("");
    setActive2("");
    setActive3("");
  };
  if (!userContext.hasActiveSubscription || userContext.parentReports.length === 0)  {
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal ms-4">
        <ParentHeaderTabs
          to={"/parent/dashboard"}
          classes={`first ${active1}`}
          text={"Parent Home"}
        />
      </ListGroup>
    )
  } else {
    tabs = (
      <ListGroup as="ul" className="list-group list-group-horizontal parent-tabs">
        <ParentHeaderTabs
          to={"/parent/dashboard"}
          classes={`first ${active1}`}
          text={"Parent Home"}
        />
  
        <ParentHeaderTabs
          to={"/parent/reports"}
          classes={active2}
          text={"Reports"}
        />
  
        <ParentHeaderTabs
          to={"/parent/pages/tos"}
          classes={active3}
          text={"Review Terms of Service"}
        />
  
      </ListGroup>
    )
  }

  useEffect(() => {
    switch (path) {
      case "dashboard":
        cleanActives();
        setActive1("active");
        break;
      case "reports":
        cleanActives();
        setActive2("active");
        break;
      case "tos":
        cleanActives();
        setActive3("active");
        break;
      default:
        cleanActives();
        break;
    }
  }, [path]);

  return (
    <div className={`navbar navbar-green parent-navbar ${justify}`}>{tabs}</div>
  )
}

export default ParentHeaderSections;